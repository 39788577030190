import React, { useEffect, useState, useContext } from 'react';
import {
  HeaderContainer,
  HeaderWrapper,
  LogoWrapper,
  SearchWrapper,
  ButtonsWrapper,
  LogoImg,
  MenuWrapper,
  BubbleWrapper,
  Bubble,
} from './HeaderStyles';
import { Button, Input, AutoComplete, notification, Avatar, Dropdown, Divider } from 'antd';
import { LogoutOutlined, FilterOutlined, MenuOutlined, CloseOutlined, UserOutlined, SearchOutlined, StarOutlined, LeftOutlined } from '@ant-design/icons';
import { removeToken, removeUserData, getUserData, getOrganizationData, removeOrganizationData, getIsAppUser } from './../../../helper';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { SUGGEST_VIDEOS_QUERY, CURRENTLY_PREMIERING_VIDEO_QUERY } from 'apollo/video/queries';
import debounce from 'lodash/debounce';
import VideoItemNotification from 'component/VideoItemNotification/VideoItemNotification';
import './notificationStyles.css';
import { ANNOUNCEMENT_QUERY, CUSTOM_BUBBLES_QUERY, CUSTOM_BUTTONS_QUERY } from 'apollo/others/queries';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import { GASetUserId } from 'helper/reactga';
import { CHECK_WAIVER_SIGN_QUERY, FREE_TRIAL_QUERY, USER_FROM_TOKEN_QUERY } from 'apollo/user/queries';
import { setUserData } from '../../../helper';
import { Icon } from '@ant-design/compatible';
import IframeModal from 'component/IframeModal/IframeModal';
import WaiverModal from 'component/WaiverModal/WaiverModal';
import FollowingModal from 'component/FollowingModal/FollowingModal';
import config from 'config';

interface Props {
  toggleFilter: Function;
}

const Header: React.FC<Props> = ({ toggleFilter }) => {
  const [organization] = useState(JSON.parse(getOrganizationData()));
  const location = useLocation();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [userData, _setUserData] = useState(JSON.parse(getUserData()) || null);
  const [announcement, setAnnouncement] = useState<any>(null);
  const [customButtons, setCustomButtons] = useState<any>([]);
  const [customBubbles, setCustomBubbles] = useState<any>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [fetching, setFetching] = useState(false);
  const [suggestedVideos, setSuggestedVideos] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const [selectButton, setSelectButton] = useState<any>(null);
  const [showWaiverModal, setShowWaiverModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [userFromToken] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
    onCompleted: async data => {
      await setUserData(JSON.stringify(data.userFromToken));
      _setUserData(data.userFromToken);
    },
  });
  useQuery(CHECK_WAIVER_SIGN_QUERY, {
    onCompleted: data => {
      // console.log('waiver sign', data);
      if (data.checkWaiverSign) {
        setShowWaiverModal(false);
      } else {
        setShowWaiverModal(true);
      }
    },
  });
  const [searchSuggest] = useLazyQuery(SUGGEST_VIDEOS_QUERY, {
    onCompleted: data => {
      let temp = [];
      temp = data.suggestVideos.map(item => ({
        key: item._id,
        label: <span key={item._id}>{item.title}</span>,
        value: item.title,
      }));
      setSuggestedVideos(temp);
      setFetching(false);
    },
  });

  useEffect(() => {
    if (!JSON.parse(getUserData())) {
      userFromToken();
    }
  }, []);

  useEffect(() => {
    userData && GASetUserId();
  }, [userData]);

  useQuery(ANNOUNCEMENT_QUERY, {
    onCompleted: data => {
      // console.log('next class', data);
      if (data.announcement && data.announcement.title) {
        setAnnouncement(data.announcement);
      }
    },
    onError: err => {
      console.error('err announcement', err);
    },
  });
  useQuery(CUSTOM_BUTTONS_QUERY, {
    onCompleted: data => {
      // console.log('next class', data);
      if (data.customButtons && data.customButtons.length > 0) {
        setCustomButtons(data.customButtons);
      }
    },
    onError: err => {
      console.error('err custom buttons', err);
    },
  });
  useQuery(CUSTOM_BUBBLES_QUERY, {
    onCompleted: data => {
      // console.log('next class', data);
      if (data.customBubbles && data.customBubbles.length > 0) {
        setCustomBubbles(data.customBubbles);
      }
    },
    onError: err => {
      console.error('err custom buttons', err);
      setCustomBubbles([]);
    },
  });
  useQuery(FREE_TRIAL_QUERY, {
    onCompleted: data => {
      // console.log('free trial', data);
      if (data.freeTrial) {
        notification.error({
          message: 'Free Trial Expiring Soon',
          duration: 0,
          description: 'You are on a free trial. It expires on September 15th, 2020. After you will need to add a video package to your account',
          placement: 'bottomLeft',
        });
      }
    },
    onError: err => {
      console.error('err free trial query', err);
    },
  });
  useQuery(CURRENTLY_PREMIERING_VIDEO_QUERY, {
    onCompleted: data => {
      if (data.currentlyPremeiringVideo.length > 0) {
        data.currentlyPremeiringVideo.map(item => {
          notification.open({
            message: 'Premiering Now',
            duration: 0,
            description: <VideoItemNotification item={item} />,
            key: item.title,
            onClick: () => {
              notification.close(item.title);
              history.push({
                pathname: '/video',
                search: `?vid=${item._id}`,
                state: { vid: item },
              });
            },
            className: 'notification',
          });
        });
      }
    },
  });

  const logOut = () => {
    removeToken();
    removeUserData();
    removeOrganizationData();
    window.location.reload();
  };

  const toggleMenu = () => {
    setShowMenu(prev => !prev);
  };

  const onSearch = value => {
    if (value != '') {
      history.push({
        pathname: '/search',
        search: `?search_query=${value}`,
      });
    }
  };

  const fetchUser = value => {
    setFetching(true);
    searchSuggest({
      variables: {
        search: value,
      },
    });
  };
  const [fetchDebounce] = useState(() => debounce(fetchUser, 1000));

  const findSuggestion = val => {
    setSearchText(val);
    if (!val) {
      setSuggestedVideos([]);
    } else {
      if (val) {
        fetchDebounce(val);
      }
    }
  };

  const dropdownMenu = (
    <div style={{ background: 'white', padding: '1em 0', boxShadow: '0px 5px 10px 5px rgba(0,0,0,0.3)' }}>
      <div style={{ padding: '0 1em', display: 'flex' }}>
        <div>
          <Avatar
            size={50}
            icon={<UserOutlined />}
            src={JSON.parse(getUserData()) && JSON.parse(getUserData()).avatarUrl}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
          <span style={{ fontSize: '1.15rem' }}>
            {userData ? userData.firstName[0].toUpperCase() + userData.firstName.slice(1) : 'first'}{' '}
            {userData ? userData.lastName[0].toUpperCase() + userData.lastName.slice(1) : 'last'}
          </span>
          <span style={{ fontSize: '1.15rem' }}>{userData && !!userData.username && userData.username}</span>
        </div>
      </div>
      <Divider style={{ margin: '0.5em 0' }} />
      {
        !(getIsAppUser('is_app_user') === 'yes') && (
          <div style={{ padding: '0 1.5em', cursor: 'pointer' }} onClick={logOut}>
            <Button type="text" style={{ padding: 0, verticalAlign: 'middle' }}>
              <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <LogoutOutlined style={{ fontSize: '1.5rem', color: '#444' }} />
                <span style={{ margin: 0, marginLeft: 10, fontSize: '1.15rem' }}>Logout</span>
              </div>
            </Button>
          </div>
        )
      }
    </div>
  );

  const handleCustomButton = button => {
    if (button.action === 'iframe') {
      setSelectButton(button);
      setShowIframeModal(true);
    }
    if (button.action === 'redirect') {
      setSelectButton(null);
      setShowIframeModal(false);
      window.location.assign(button.url);
    }
  };
  const onFilterDone = filters => {
    let tempFilterField = {
      date: filters.date == null ? 'all' : filters.date,
      trainer: filters.trainer || { value: 'all', label: 'All' },
      activity: filters.activity || { value: 'all', label: 'All' },
      organization: filters.organization || { value: 'all', label: 'All' },
      intensity: filters.intensity || 'all',
      equipments: filters.equipments?.length > 0 ? filters.equipments : [],
      tags: filters.tags?.length > 0 ? filters.tags : [],
      duration: filters.duration > 0 ? filters.duration : 'all',
    };
    history.push({
      pathname: '/videolist/Filtered%20Videos',
      state: { ...tempFilterField },
    });
  };

  const headerButtons =
    customButtons &&
    customButtons.map(item => (
      <span key={item._id}>
        <Button type="link" onClick={() => handleCustomButton(item)}>
          <Icon type={item.icon} theme="outlined" style={{ fontSize: '24px', color: '#444' }} />
        </Button>
      </span>
    ));

  return (
    <>
      {showBanner && announcement && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            background: themeContext.colors.primary,
            padding: '0.5em 1em',
          }}
        >
          <div style={{ textAlign: 'center', width: '95%', order: 2 }}>
            {/* <span style={{ color: 'white', textAlign: 'center', marginRight: '5%' }}>{`Next Class: Hatha flow - ${moment().format(
              'DD MMM, YYYY'
            )} - ${moment().format('hh:mm a')}`}</span> */}
            {announcement.type === 'nextOnlineClass' ? (
              <span style={{ color: 'white', marginRight: '5%', fontSize: '1rem' }}>
                {`Next Class: ${announcement.title} - ${announcement.instructor !== null ? `${announcement.instructor} -` : ''} ${moment(
                  announcement.startTime
                ).format('DD MMM, YYYY')} - ${moment(announcement.startTime).format('hh:mm a')}`}
              </span>
            ) : (
              <span style={{ color: 'white', marginRight: '5%', fontSize: '1rem' }}>{`${announcement.title}`}</span>
            )}
          </div>
          <div style={{ textAlign: 'left', width: '5%', order: 1 }}>
            <CloseOutlined style={{ color: 'white' }} onClick={() => setShowBanner(false)} />
          </div>
        </div>
      )}
      <HeaderWrapper>
        <HeaderContainer>
          {
            !(getIsAppUser('is_app_user') === 'yes') ? (
              <LogoWrapper>
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <Link to="/">
                    <LogoImg
                      src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
                      }}
                    />
                  </Link>
                </div>
                <MenuWrapper>
                  <Button type="link" onClick={toggleMenu}>
                    <MenuOutlined style={{ fontSize: '2em', color: '#444' }} />
                  </Button>
                </MenuWrapper>
              </LogoWrapper>
            ) : (
              !(window.location.href === config.WEB_URL) ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', margin: '1em 0' }}>
                  <LeftOutlined onClick={() => history.push('/')} style={{ fontSize: 24 }} />
                </div>
              ) : null
            )
          }
          {/* <LogoWrapper>
            {
              !(getIsAppUser('is_app_user') === 'yes') ? (
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <Link to="/">
                    <LogoImg
                      src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
                      }}
                    />
                  </Link>
                </div>
              ) : !(window.location.href === config.WEB_URL) ? <LeftOutlined onClick={() => history.goBack()} style={{ fontSize: 24 }} /> : null
            }
            <MenuWrapper>
              <Button type="link" onClick={toggleMenu}>
                <MenuOutlined style={{ fontSize: '2em', color: '#444' }} />
              </Button>
            </MenuWrapper>
          </LogoWrapper> */}
          <SearchWrapper {...{ show: showMenu }}>
            <AutoComplete options={suggestedVideos} onSelect={onSearch} style={{ width: '100%' }} onSearch={findSuggestion}>
              <Input.Search
                size="large"
                placeholder="Search ..."
                style={{ width: '100%' }}
                onSearch={onSearch}
                enterButton={
                  <Button style={{ background: themeContext.colors.primary }}>
                    <SearchOutlined style={{ color: 'white' }} />
                  </Button>
                }
              />
            </AutoComplete>
          </SearchWrapper>
          <ButtonsWrapper {...{ show: showMenu }}>
            {headerButtons}
            {/* <span>
              <Button type="link" onClick={() => setShowOnlineClassModal(true)}>
                <Icon type="schedule" theme="outlined" style={{ fontSize: '24px', color: 'red' }} />
              </Button>
            </span> */}
            {((location && location.pathname == '/') || (location && location.pathname == '/videolist/Filtered Videos')) && (
              <span>
                <Button type="link" onClick={() => toggleFilter(true)}>
                  <FilterOutlined style={{ fontSize: '24px', color: '#444' }} />
                </Button>
              </span>
            )}
            <span>
              <Button type="link" onClick={() => setShowFollowingModal(true)}>
                <StarOutlined style={{ fontSize: '24px', color: '#444' }} />
                {/* <FilterOutlined style={{ fontSize: '24px', color: '#444' }} /> */}
              </Button>
            </span>
            <span>
              <Dropdown overlay={dropdownMenu} placement="bottomRight" trigger={['click']}>
                <Avatar size={40} icon={<UserOutlined />} src={userData && userData.avatarUrl} style={{ cursor: 'pointer' }} />
              </Dropdown>
            </span>
          </ButtonsWrapper>
        </HeaderContainer>
        {location && location.pathname == '/' && customBubbles && customBubbles.length > 0 && (
          <div
            style={{
              borderTop: '1px solid #dbdbdb',
            }}
          >
            <BubbleWrapper>
              {customBubbles.map(item => {
                return (
                  <Bubble
                    key={item._id}
                    onClick={() => {
                      let obj = {};
                      if (item.bubbleType == 'organization') {
                        obj = {
                          organization: { label: item.bubbleContent.name, value: item.bubbleContent._id },
                        };
                      }
                      if (item.bubbleType == 'trainer') {
                        obj = {
                          trainer: { label: item.bubbleContent.name, value: item.bubbleContent._id },
                        };
                      }
                      if (item.bubbleType == 'activity') {
                        obj = {
                          activity: { label: item.bubbleContent.name, value: item.bubbleContent._id },
                        };
                      }
                      if (item.bubbleType == 'tag') {
                        obj = {
                          tags: [item.tag],
                        };
                      }
                      onFilterDone(obj);
                    }}
                  >
                    {item.bubbleType === 'tag' ? item.tag : item.bubbleContent.name}
                  </Bubble>
                );
              })}
            </BubbleWrapper>
          </div>
        )}
        <IframeModal
          visible={showIframeModal}
          onCancel={() => {
            setSelectButton(null);
            setShowIframeModal(false);
          }}
          payload={selectButton}
        />
        <WaiverModal visible={showWaiverModal} onDone={() => setShowWaiverModal(false)} />
        <FollowingModal visible={showFollowingModal} onDone={() => setShowFollowingModal(false)} />
      </HeaderWrapper>
    </>
  );
};

export default Header;
