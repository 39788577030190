import { gql } from 'apollo-boost';

export const SUGGEST_VIDEOS_QUERY = gql`
  query suggestVideos($search: String!) {
    suggestVideos(search: $search) {
      _id
      title
    }
  }
`;
