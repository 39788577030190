import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from '../helper';

const PrivateRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={
                props =>
                    !!getToken() ? (
                        <React.Fragment>
                            <Component />
                        </React.Fragment>
                    ) : (
                        <Redirect to='/login' />
                    )
            }
        />
    )
}

export default PrivateRoutes
