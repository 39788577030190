import React, { useEffect } from 'react';
import VideoFilterModal from '../../../component/VideoFilterModal/VideoFilterModal';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_IN_DISPLAY_ORDER_QUERY } from 'apollo/activity/queries';
import { NetworkStatus } from 'apollo-boost';
import VideoListScroll from 'component/VideoListScroll/VideoListScroll';
import { HomeWrapper, TitleWrapper } from './HomeStyles';
import SkeletonCard from 'component/SkeletonCard/SkeletonCard';
import HomeVideoComp from 'component/HomeVideoComp/HomeVideoComp';
// import { GApageView } from 'helper/reactga';

const Home = ({ showFilterModal, setShowFilterModal, filtersMain, setFilters }) => {
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data, refetch, networkStatus } = useQuery(ACTIVITIES_IN_DISPLAY_ORDER_QUERY, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    refetch();
    setFilters(null);
  }, [location]);

  // if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner />;
  // if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('data', data);
  // }
  // if (error) {
  //   console.log('error', error);
  // }

  const onClickItem = item => {
    if (item) {
      history.push({
        pathname: '/video',
        search: `?vid=${item?._id}`,
        state: { vid: item },
      });
    }
  };

  const onFilterDone = filters => {
    // setFilterField(tempFilterField);
    setFilters({ ...filters });
    history.push({
      pathname: '/videolist/Filtered%20Videos',
      state: { ...filters },
    });
    setShowFilterModal(false);
  };
  const onFilterCancel = () => {
    // console.log('filter cancelled');
    setShowFilterModal(false);
  };
  return (
    <HomeWrapper>
      {(loading || networkStatus === NetworkStatus.refetch) && (
        <>
          {[1, 2, 3].map(item => (
            <div key={item}>
              <SkeletonCard />
            </div>
          ))}
        </>
      )}
      {!loading &&
        networkStatus !== NetworkStatus.refetch &&
        data &&
        data.activitiesInDisplayOrder &&
        [
          { _id: '1', name: 'Latest Videos', type: 'latest' },
          {
            _id: '2',
            name: 'Popular Videos',
            type: 'popular',
          },
          {
            _id: '3',
            name: 'Shared By Others',
            type: 'sharedByOthers',
          },
          ...data.activitiesInDisplayOrder,
        ].map(activity => {
          return <HomeVideoComp key={activity._id} item={activity} onClickItem={onClickItem} />;
        })}
      <VideoFilterModal onDone={onFilterDone} onCancel={onFilterCancel} visible={showFilterModal} filters={filtersMain} />
    </HomeWrapper>
    // <div></div>
  );
};

export default Home;
