import React, { useEffect, useState, useContext } from 'react';
import { Card, Divider, Row, Col, Avatar, Tag, Button, Collapse } from 'antd';
import { UserOutlined, DislikeFilled, LikeFilled } from '@ant-design/icons';
import moment from 'moment';
import TrainerDetailModal from './../TrainerDetailModal/TrainerDetailModal';
import { VideoTitle, TrainerName, DateLikeWrapper } from './VideoPlayerCompStyles';
import viewFormatter from 'helper/viewsFormatter';
import { useTheme, ThemeContext } from 'styled-components';
import { LIKE_VIDEO_MUTATION, DISLIKE_VIDEO_MUTATION } from 'apollo/video/mutation';
import { useMutation } from '@apollo/react-hooks';
import VideoPlayer from './../VideoPlayer/VideoPlayer';
import { GAEvent } from 'helper/reactga';

const VideoPlayerComp = ({ videoDetails }) => {
  const [showTrainerDetail, setShowTrainerDetail] = useState(false);
  const [isLiked, setIsLiked] = useState(videoDetails.isLiked);
  const [totalLikes, setTotalLikes] = useState(videoDetails.totalLikes);
  const [isDisliked, setIsDisliked] = useState(videoDetails.isdisLiked);
  const [totalDislikes, setTotalDislikes] = useState(videoDetails.totaldisLikes);
  // const [playerMarkers, setPlayerMarkers] = useState<any>([]);
  let playerMarkers: any = [];
  const markers = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  const [likeVideo] = useMutation(LIKE_VIDEO_MUTATION);
  const [dislikeVideo] = useMutation(DISLIKE_VIDEO_MUTATION);
  const [videoJsOptions, setVideoJsOptions] = useState({
    autoplay: true,
    controls: true,
    aspectRatio: '16:9',
    sources: [
      {
        src: videoDetails.url,
        // type: 'application/x-mpegURL',
      },
    ],
  });
  const themeContext = useContext(ThemeContext);
  // const aasd = () => {
  //   let frame: any = document.getElementById('iframenew');
  //   console.log('frame 2', frame);

  //   var elmnt = frame.contentWindow.document.querySelectorAll('#facility-item');
  //   console.log('el', elmnt);
  //   let trainerName = document.getElementById('trainer-details');
  //   if (trainerName && elmnt.length > 0) {
  //     console.log('trainer', trainerName);
  //     trainerName.appendChild(elmnt && elmnt[0]);
  //   }
  // };
  const openTrainerDetailModal = () => {
    if (videoDetails.trainer && videoDetails.trainer.trainerDetailUrl) {
      setShowTrainerDetail(true);
    }
  };

  const onLike = () => {
    if (!isLiked) {
      if (isDisliked) {
        setIsDisliked(false);
        setTotalDislikes(prev => prev - 1);
        GAEvent('Videos', `Revert Dislike Video`, videoDetails.title, null, {
          dimension6: videoDetails.trainer.name,
          dimension5: videoDetails.title,
          dimension7: videoDetails.activity.name,
          dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
          dimension9: videoDetails.organizationId,
          dimension10: videoDetails._id,
          metric10: 1,
        });
      }
      GAEvent('Videos', `Like Video`, videoDetails.title, null, {
        dimension6: videoDetails.trainer.name,
        dimension5: videoDetails.title,
        dimension7: videoDetails.activity.name,
        dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
        dimension9: videoDetails.organizationId,
        dimension10: videoDetails._id,
        metric7: 1,
      });
      setTotalLikes(prev => prev + 1);
    } else {
      GAEvent('Videos', `Revert Like Video`, videoDetails.title, null, {
        dimension6: videoDetails.trainer.name,
        dimension5: videoDetails.title,
        dimension7: videoDetails.activity.name,
        dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
        dimension9: videoDetails.organizationId,
        dimension10: videoDetails._id,
        metric8: 1,
      });
      setTotalLikes(prev => prev - 1);
    }
    likeVideo({
      variables: {
        input: {
          _id: videoDetails._id,
          type: !isLiked,
        },
      },
    });
    setIsLiked(prev => !prev);
  };
  const onDislike = () => {
    if (!isDisliked) {
      if (isLiked) {
        setIsLiked(false);
        GAEvent('Videos', `Revert Like Video`, videoDetails.title, null, {
          dimension6: videoDetails.trainer.name,
          dimension5: videoDetails.title,
          dimension7: videoDetails.activity.name,
          dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
          dimension9: videoDetails.organizationId,
          dimension10: videoDetails._id,
          metric8: 1,
        });
        setTotalLikes(prev => prev - 1);
      }
      GAEvent('Videos', `Dislike Video`, videoDetails.title, null, {
        dimension6: videoDetails.trainer.name,
        dimension5: videoDetails.title,
        dimension7: videoDetails.activity.name,
        dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
        dimension9: videoDetails.organizationId,
        dimension10: videoDetails._id,
        metric9: 1,
      });
      setTotalDislikes(prev => prev + 1);
    } else {
      GAEvent('Videos', `Revert Dislike Video`, videoDetails.title, null, {
        dimension6: videoDetails.trainer.name,
        dimension5: videoDetails.title,
        dimension7: videoDetails.activity.name,
        dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
        dimension9: videoDetails.organizationId,
        dimension10: videoDetails._id,
        metric10: 1,
      });
      setTotalDislikes(prev => prev - 1);
    }
    dislikeVideo({
      variables: {
        input: {
          _id: videoDetails._id,
          type: !isDisliked,
        },
      },
    });
    setIsDisliked(prev => !prev);
  };

  return (
    <>
      <Card bodyStyle={{ padding: '1em' }}>
        <div style={{ width: '100%' }}>
          <VideoPlayer
            // videoJsOptions={videoJsOptions}
            video={videoDetails}
            onPlay={(currentTime: number) => {
              if (currentTime < 2) {
                GAEvent('Videos', 'Start', videoDetails.title, null, {
                  dimension6: videoDetails.trainer.name,
                  dimension5: videoDetails.title,
                  dimension7: videoDetails.activity.name,
                  dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
                  dimension9: videoDetails.organizationId,
                  dimension10: videoDetails._id,
                  metric5: 1,
                });
              }
            }}
            onEnd={duration => {
              GAEvent('Videos', `Complete`, videoDetails.title, null, {
                dimension6: videoDetails.trainer.name,
                dimension5: videoDetails.title,
                dimension7: videoDetails.activity.name,
                dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
                dimension9: videoDetails.organizationId,
                dimension10: videoDetails._id,
                metric6: 1,
                metric4: Math.round(0.1 * duration),
                metric3: 10,
                metric13: Math.round(0.1 * (videoDetails.size || 204800)),
              });
            }}
            onTimeUpdate={(currentTime, duration) => {
              let playedPercent = Math.floor((currentTime * 100) / duration);
              if (markers.indexOf(playedPercent) > -1 && playerMarkers.indexOf(playedPercent) == -1) {
                // setPlayerMarkers((prev) => [...prev, playedPercent]);
                playerMarkers.push(playedPercent);
                GAEvent('Videos', `Watched ${playedPercent}%`, videoDetails.title, null, {
                  dimension6: videoDetails.trainer.name,
                  dimension5: videoDetails.title,
                  dimension7: videoDetails.activity.name,
                  dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
                  dimension9: videoDetails.organizationId,
                  dimension10: videoDetails._id,
                  metric4: Math.round(0.1 * duration),
                  metric3: 10,
                  metric13: Math.round(0.1 * (videoDetails.size || 204800)),
                });
              }
            }}
          />

          {/* <video
            controls
            preload="auto"
            style={{ width: '100%', maxHeight: '700px' }}
            autoPlay={false}
            onContextMenu={(e) => e.preventDefault()}
            controlsList="nodownload"
          >
            <source src={videoDetails.url} />
          </video> */}
          {/* <VideoPlayer controls={true} src={videoDetails.url} poster={videoDetails.thumbnail} className="videoplayerhls" /> */}
        </div>
        <VideoTitle>
          {videoDetails.title ||
            `${videoDetails.activity.name} - ${
              videoDetails.premiereAt ? moment(videoDetails.premiereAt).format('MMM D, YYYY') : moment(videoDetails.createdAt).format('MMM D, YYYY')
            }`}
        </VideoTitle>
        <DateLikeWrapper>
          <div>
            <p style={{ margin: 0, padding: 0, fontSize: '1.2rem' }}>
              {videoDetails.premiereAt ? moment(videoDetails.premiereAt).format('MMM D, YYYY') : moment(videoDetails.createdAt).format('MMM D, YYYY')}
            </p>
          </div>
          <div>
            <Button
              type="text"
              style={{ padding: 0, marginRight: '0.5em', color: isLiked ? themeContext.colors.secondary : themeContext.colors.lightGrey }}
              onClick={onLike}
            >
              <LikeFilled style={{ fontSize: '1.5rem' }} />
              <span style={{ fontSize: '1.25rem' }}>{viewFormatter(totalLikes, 1)}</span>
            </Button>
            <Button
              type="text"
              style={{ padding: 0, color: isDisliked ? themeContext.colors.secondary : themeContext.colors.lightGrey }}
              onClick={onDislike}
            >
              <DislikeFilled style={{ fontSize: '1.5rem' }} />
              <span style={{ fontSize: '1.25rem' }}>{viewFormatter(totalDislikes, 1)}</span>
            </Button>
          </div>
        </DateLikeWrapper>
        {!!videoDetails.parentOrganizationId && videoDetails.parentOrganization && !!videoDetails.parentOrganization.name && (
          <Tag style={{ fontSize: '1rem' }}>{`Video by ${videoDetails.parentOrganization.name}`}</Tag>
        )}
        <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
        <Row style={{ marginBottom: '1em' }}>
          <Col>
            <span onClick={() => openTrainerDetailModal()} style={{ cursor: 'pointer' }}>
              <Avatar size={60} icon={<UserOutlined />} src={videoDetails.trainer && videoDetails.trainer.trainerPicUrl} />
            </span>
          </Col>
          <Col>
            <div style={{ marginLeft: '1em' }}>
              <TrainerName onClick={() => openTrainerDetailModal()}>
                {(videoDetails.trainer && videoDetails.trainer.name) || 'Trainer Name'}
              </TrainerName>
              <p style={{ margin: 0, padding: 0, fontSize: '1.15rem', fontWeight: 300 }}>
                {(videoDetails.activity && videoDetails.activity.name) || 'Activity'}
              </p>
            </div>
          </Col>
        </Row>
        {videoDetails.tags && videoDetails.tags.length > 0 && videoDetails.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        <p style={{ fontSize: '1rem' }}>{videoDetails.description}</p>
      </Card>
      <TrainerDetailModal
        visible={showTrainerDetail}
        onCancel={() => setShowTrainerDetail(false)}
        trainerDetailUrl={videoDetails.trainer && videoDetails.trainer.trainerDetailUrl}
      />
    </>
  );
};

export default VideoPlayerComp;
