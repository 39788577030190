import ReactGA from 'react-ga';
import { getUserData } from './utils';

export const initGA = () => {
  ReactGA.initialize('UA-170455245-2', {
    debug: true,
    titleCase: false,
  }); // put your tracking id here
  // const userId = JSON.parse(getUserData()).ClientID;

  // ReactGA.set({ userId: userId });
};

export const GAEvent = (category, action, label = null, value: number | null = null, customDimensionsMetrics: Object | null = null) => {
  let eventDetails: any = {
    category,
    action,
    ...(label ? { label: label } : {}),
    ...(value ? { value: value } : {}),
    ...(customDimensionsMetrics ? { ...customDimensionsMetrics } : {}),
  };
  // console.log('eventDetails', eventDetails);
  if (!process.env.REACT_APP_SERVER_TEST) ReactGA.event({ ...eventDetails });
};

export const GASetUserId = () => {
  const userId = JSON.parse(getUserData()).userId;
  ReactGA.set({ userId: userId });
};

export const GApageView = page => {
  ReactGA.pageview(page);
};
