import React from 'react';
import { Link } from 'react-router-dom';
import VideoListScroll from 'component/VideoListScroll/VideoListScroll';
import { TitleWrapper, LoadingDiv } from 'view/Main/Home/HomeStyles';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_BY_TYPE_QUERY } from 'apollo/video/queries';
import Loader from 'react-loader-spinner';

const HomeVideoComp = ({ item, onClickItem }) => {
  const { data, loading } = useQuery(VIDEOS_BY_TYPE_QUERY, {
    variables: {
      type: item.type || 'activity',
      value: item._id,
    },
  });

  const renderTitle = () => {
    const TitleComp = (
      <>
        {item.name == 'Latest Videos' || item.name == 'Popular Videos' || item.name == 'Shared By Others' ? (
          <TitleWrapper>{item.name}</TitleWrapper>
        ) : (
          <Link to={{ pathname: `/videolist/${item.name}`, state: { activity: { value: item._id } } }}>
            <TitleWrapper>{item.name}</TitleWrapper>
          </Link>
        )}
      </>
    );
    if (loading) {
      return TitleComp;
    } else {
      if (data && data.videosByType && data.videosByType.videos.length > 0) {
        return TitleComp;
      } else {
        return null;
      }
    }
  };

  return (
    <div key={item._id}>
      {renderTitle()}
      {loading ? (
        <LoadingDiv>
          <Loader type="TailSpin" color="#202541" />
        </LoadingDiv>
      ) : (
        <>
          {data && data.videosByType && data.videosByType.videos.length > 0 ? (
            <VideoListScroll videos={data.videosByType.videos} onClick={onClickItem} showLast={item.type ? false : true} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default HomeVideoComp;
