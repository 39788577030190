import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragment';

export const VIDEOS_FILTER_QUERY = gql`
  query videosFilter($videosFilterInput: VideosFilterInput, $paginationInput: PaginationInput) {
    videosFilter(videosFilterInput: $videosFilterInput, paginationInput: $paginationInput) {
      videos {
        ...Video
      }
      pagination {
        page
        total
        limit
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
