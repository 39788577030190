import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const VideoTitle = styled.h3`
  font-size: 1.25rem;
  overflow: hidden;
  margin: 0;
  line-height: 1.4rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const InfoWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
