import styled, { StyledFunction } from 'styled-components';
import { Button } from 'antd';

export const VideoTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

export const TrainerName = styled.h3`
  cursor: pointer;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.4rem;
`;

export const DateLikeWrapper = styled.div`
  display: flex;

  /* flex-direction: column; */
  /* @media (min-width: 500px) { */
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  /* } */
`;

// export const LikeButton: any = styled(Button)`
//   padding: 0;
//   margin-right: 0.5em;
//   color: red;
// `;
// export const DislikeButton: any = styled(Button)`
//   padding: 0;
//   margin-right: 0.5em;
//   color: blue;
// `;
