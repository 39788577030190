import React, { useRef, useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import VideoItem from 'component/VideoItem/VideoItem';
import './styles.css';
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { CardWrapper, LeftArrowDiv, RightArrowDiv, LeftButton, RightButton } from './VideoListScrollStyles';
import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';

const Arrow = ({ text }) => {
  return <div>{text == 'left' ? <DoubleLeftOutlined style={{ fontSize: '2em' }} /> : <DoubleRightOutlined style={{ fontSize: '2em' }} />}</div>;
};
const VideoListScroll = ({ videos, onClick, showLast }) => {
  const menuRef: any = useRef(null);
  const history = useHistory();
  const [showLeft, setShowLeft] = useState(true);
  const [showRight, setShowRight] = useState(true);
  const onClickItem = val => {
    // console.log('item', val);
    // onClick(val);
  };

  const onSelect = selected => {
    // console.log('s', selected);
    const videoItem = videos.find(item => item._id == selected);
    // console.log('item', hello);
    onClick(videoItem);
  };
  const onUpdate = ss => {
    // const s = { ...menuRef.current }.state;
    // console.log('state', menuRef.current);
    // console.log('statesssss', s);
    // if (ss.translate == 0) {
    //   setShowLeft(false);
    //   setShowRight(true);
    // }
    // setShowLeft((menuRef as any).current.state.leftArrowVisible);
    // setShowRight((menuRef as any).current.state.rightArrowVisible);
  };

  const onLastClick = last => {
    history.push({
      pathname: `/videolist/${last.activity}`,
      state: { activity: { value: last.activityId } },
    });
  };

  const menu = [...videos, ...(showLast ? [{ last: true, activityId: videos[0].activity._id, activity: videos[0].activity.name }] : [])].map(item => {
    if (item.last) {
      return (
        <CardWrapper key={item.activityId}>
          <Card
            onClick={() => onLastClick(item)}
            style={{
              cursor: 'pointer',
              background: 'transparent',
            }}
            bodyStyle={{ padding: '0em' }}
          >
            <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'white',
                }}
              >
                <h3
                  style={{
                    fontSize: '1.25rem',
                    // overflow: hidden;
                    margin: 0,
                    lineHeight: '1.4rem',
                  }}
                >
                  Click here to view more videos
                </h3>
              </div>
            </div>
          </Card>
        </CardWrapper>
      );
    } else {
      return (
        <CardWrapper key={item._id}>
          <VideoItem onClick={onClickItem} item={item} />
        </CardWrapper>
      );
    }
  });

  return (
    <div>
      {showLeft && (
        <LeftArrowDiv>
          <LeftButton
            onClick={() => {
              menuRef && (menuRef as any).current.handleArrowClick();
            }}
          >
            <DoubleLeftOutlined style={{ fontSize: '2em' }} />
          </LeftButton>
        </LeftArrowDiv>
      )}
      {showRight && (
        <RightArrowDiv>
          <RightButton
            onClick={() => {
              menuRef && (menuRef as any).current.handleArrowClickRight();
            }}
          >
            <DoubleRightOutlined style={{ fontSize: '2em' }} />
          </RightButton>
        </RightArrowDiv>
      )}
      <ScrollMenu
        alignCenter={false}
        // arrowLeft={<Arrow text="left" />}
        // arrowRight={<Arrow text="right" />}
        clickWhenDrag={false}
        data={menu}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        onSelect={onSelect}
        onUpdate={onUpdate}
        ref={menuRef}
        scrollToSelected={false}
        transition={1}
        translate={0}
        itemStyle={{ outline: 'none', verticalAlign: 'top' }}
        wheel={false}
      />
    </div>
  );
};

export default VideoListScroll;
