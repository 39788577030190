import React, { useState, useEffect } from 'react';
import { List, Tag } from 'antd';
import VideoItem from '../../../component/VideoItem/VideoItem';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import VideoFilterModal from '../../../component/VideoFilterModal/VideoFilterModal';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_FILTER_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import { VideoListWrapper, TitleWrapper } from './VideoListStyles';

const VideoList: React.FC<any> = ({ showFilterModal, setShowFilterModal, filtersMain, setFilters }) => {
  const [filterField, setFilterField] = useState<Object | null>(null);
  const [filterState, setFilterState] = useState<any>(null);
  const location: any = useLocation();
  const history = useHistory();
  const { activity } = useParams();
  useEffect(() => {
    if (location) {
      if (location.state) {
        setFilters({ ...location.state });
        setFilterState({ ...location.state });
        if (activity == 'Filtered Videos') {
          let tempFilterField = {
            date: location.state.date == null ? 'all' : location.state.date,
            trainer: location.state.trainer?.value === 'all' ? 'all' : location.state.trainer.label,
            activity: location.state.activity?.value === 'all' ? 'all' : location.state.activity.label,
            location: location.state.organization?.value === 'all' ? 'all' : location.state.organization.label,
            intensity: location.state.intensity || 'all',
            equipments: location.state.equipments?.length > 0 ? location.state.equipments.join(',') : 'all',
            tags: location.state.tags?.length > 0 ? location.state.tags.join(',') : 'all',
            duration: location.state.duration > 0 ? location.state.duration : 'all',
          };
          setFilterField(tempFilterField);
        }
      }
    }
  }, [location]);

  const { loading, error, data, refetch } = useQuery(VIDEOS_FILTER_QUERY, {
    variables: {
      videosFilterInput: {
        ...(filterState?.activity?.value !== 'all' && { activity: filterState?.activity?.value }),
        ...(filterState?.trainer?.value !== 'all' && { trainer: filterState?.trainer?.value }),
        ...(filterState?.organization?.value !== 'all' && { organization: filterState?.organization?.value }),
        ...(filterState?.intensity !== 'all' && { intensity: filterState?.intensity }),
        ...(filterState?.equipments?.length > 0 && { equipments: filterState?.equipments }),
        ...(filterState?.duration && filterState?.duration > 0 && { duration: filterState?.duration }),
        ...(filterState?.tags && filterState?.tags.length > 0 && { tags: filterState?.tags }),
      },
    },
    fetchPolicy: 'network-only',
  });
  // useEffect(() => {
  //   if (data) {
  //     console.log('data', data);
  //   }
  // }, [data]);
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('filter data', data);
  // }

  const onClickItem = item => {
    // console.log('item clicker', item.title);
    history.push({
      pathname: '/video',
      search: `?vid=${item._id}`,
      state: { vid: item },
    });
  };

  const onFilterDone = filters => {
    setFilters({ ...filters });
    let tempFilterField = {
      date: filters.date == null ? 'all' : filters.date,
      trainer: filters.trainer.value === 'all' ? 'all' : filters.trainer.label,
      activity: filters.activity.value === 'all' ? 'all' : filters.activity.label,
      location: filters.organization.value === 'all' ? 'all' : filters.organization.label,
      intensity: filters.intensity || 'all',
      equipments: filters.equipments?.length > 0 ? filters.equipments.join(',') : 'all',
      tags: filters.tags?.length > 0 ? filters.tags.join(',') : 'all',
      duration: filters.duration > 0 ? filters.duration : 'all',
    };
    setFilterField(tempFilterField);
    refetch({
      videosFilterInput: {
        ...(filters?.activity?.value !== 'all' && { activity: filters?.activity?.value }),
        ...(filters?.trainer?.value !== 'all' && { trainer: filters?.trainer?.value }),
        ...(filters?.organization?.value !== 'all' && { organization: filters?.organization?.value }),
        ...(filters?.intensity !== 'all' && { intensity: filters?.intensity }),
        ...(filters?.equipments?.length > 0 && { equipments: filters?.equipments }),
        ...(filters?.duration && filters?.duration > 0 && { duration: filters?.duration }),
        ...(filters?.tags && filters?.tags.length > 0 && { tags: filters?.tags }),
      },
    });
    setShowFilterModal(false);
  };
  const onFilterCancel = () => {
    // console.log('filter cancelled');
    setShowFilterModal(false);
  };

  return (
    <VideoListWrapper>
      <div>
        <TitleWrapper>{activity}</TitleWrapper>
      </div>
      {filterField && (
        <div style={{ marginBottom: '10px' }}>
          {Object.keys(filterField).map((keyName, i) => (
            <Tag onClick={() => setShowFilterModal(true)} key={keyName}>{`${keyName.replace(/^./, keyName[0].toUpperCase())}: ${
              filterField[keyName]
            }`}</Tag>
          ))}
        </div>
      )}
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 5,
          xxl: 5,
        }}
        dataSource={data.videosFilter.videos}
        renderItem={item => (
          <List.Item>
            <VideoItem onClick={onClickItem} item={item} />
          </List.Item>
        )}
      />
      <VideoFilterModal onDone={onFilterDone} onCancel={onFilterCancel} visible={showFilterModal} filters={filtersMain} />
    </VideoListWrapper>
  );
};

export default VideoList;
