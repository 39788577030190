import { gql } from 'apollo-boost';

export const USER_FOLLOWING_QUERY = gql`
  query userFollowing($_id: ID) {
    userFollowing(_id: $_id) {
      followingTrainer
      followingActivity
    }
  }
`;
