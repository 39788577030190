import React, { useState } from 'react';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './globalStyles';
import Theme from 'theme';
import { useQuery } from '@apollo/react-hooks';
import { ORGANIZATION_FROM_SUBDOMAIN_QUERY } from 'apollo/organization/queries';
import LoadingSpinner from 'component/Loader';
import { setOrganizationData } from 'helper';


const App: React.FC = () => {
  const [theme, setTheme] = useState<any>(null);
  const [subdomainError, setSubdomainError] = useState<any>(false);
  const getSubdomain = () => {
    const { host } = window.location;
    return host;
  };

  useQuery(ORGANIZATION_FROM_SUBDOMAIN_QUERY, {
    variables: {
      domain: getSubdomain(),
    },
    onCompleted: data => {
      if (data && data.organizationFromSubdomain) {
        if (data.organizationFromSubdomain._id == '5f60bcc4cce4855acd244bd8') {
          const script = document.createElement('script');
          script.id = 'exceedChatWidget';
          script.src = 'https://chat.exceed.ai/exceedChatWidget.js?sequenceid=60254e33aecd91540e2095c8';
          script.async = true;
          document.body.appendChild(script);
        }
        setOrganizationData(JSON.stringify(data.organizationFromSubdomain));
      } else {
        setSubdomainError(true);
      }
      if (data && data.organizationFromSubdomain && data.organizationFromSubdomain.theme && data.organizationFromSubdomain.theme.colors) {
        setTheme(data.organizationFromSubdomain.theme);
      } else {
        setTheme({
          colors: {
            primary: '#00205B',
            secondary: '#4EC3E0',
          },
        });
      }
    },
    onError: err => {
      console.error('error sub', err);
      setSubdomainError(true);
      setTheme({
        colors: {
          primary: 'red',
          secondary: '#4EC3E0',
        },
      });
    },
  });
  if (subdomainError) {
    return (
      <React.Fragment>
        <h2 style={{ color: 'red', textAlign: 'center' }}>Invalid Domain!</h2>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <BrowserRouter>
        {theme && !subdomainError ? (
          <Theme theme={theme}>
            <GlobalStyles />
            <Router />
          </Theme>
        ) : (
          <LoadingSpinner />
        )}
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
