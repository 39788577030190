import React, { useState } from 'react';
import { Button, Modal, Input, Checkbox, DatePicker, Form, notification } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_WAIVER_FORM_QUERY } from 'apollo/organization/queries';
import Loader from 'react-loader-spinner';
import { SIGN_WAIVER_PDF_MUTATION } from 'apollo/user/mutations';
import { removeToken, removeUserData } from 'helper';
import moment from 'moment';

const WaiverModal = ({ visible, onDone }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [signWaiver] = useMutation(SIGN_WAIVER_PDF_MUTATION);
  const [form] = Form.useForm();

  const [formItems, setFormItems] = useState<any>([]);
  useQuery(GET_WAIVER_FORM_QUERY, {
    onCompleted: data => {
      setLoading(false);
      if (data.getWaiverForm.length < 1) onDone();
      setFormItems(data.getWaiverForm || []);
    },
    onError: err => {
      setFormItems([]);
      setLoading(false);
    },
    fetchPolicy: 'network-only',
  });
  const sign = () => {
    setSubmitting(true);
    form
      .validateFields()
      .then(done => {
        const input = document.getElementById('waiver');
        // console.log('input', input?.outerHTML);
        signWaiver({
          variables: {
            waiver: input?.outerHTML,
          },
        })
          .then(result => {
            setSubmitting(false);
            if (result.data.signWaiverPdf) {
              notification.success({
                message: 'Waiver Signed',
              });
              onDone();
            } else {
              notification.error({
                message: 'Waiver Sign Failed',
                // description: err.toString().replace('Error: GraphQL error:', ''),
              });
            }
          })
          .catch(err => {
            setSubmitting(false);
            console.error('err', err);
            notification.error({
              message: 'Waiver Sign Failed',
              // description: err.toString().replace('Error: GraphQL error:', ''),
            });
          });
      })
      .catch(err => {
        console.log('err', err);
        setSubmitting(false);
      });
  };
  const logOut = () => {
    removeToken();
    removeUserData();
    window.location.reload();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      maskClosable={false}
      footer={[
        <Button key="logout" onClick={logOut} disabled={loading}>
          Logout
        </Button>,
        <Button key="submit" type="primary" onClick={sign} disabled={loading} loading={submitting}>
          Submit
        </Button>,
      ]}
    >
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      ) : (
        <div id="waiver" style={{ width: '100%' }}>
          <Form layout="horizontal" form={form} name="login">
            {formItems.map((item: any, index) => {
              if (item.type == 'title') {
                return (
                  <div key={`${index}`} style={{ marginBottom: 10 }}>
                    <h2>{item.title}</h2>
                  </div>
                );
              }
              if (item.type == 'text') {
                return (
                  <div key={`${index}`} style={{ marginBottom: 10 }}>
                    <p>{item.text}</p>
                  </div>
                );
              }
              if (item.type == 'textInput') {
                return (
                  <Form.Item
                    key={`${index}`}
                    label={item.label}
                    name={item.label}
                    labelAlign="left"
                    rules={[{ required: true, message: 'Cannot be empty' }]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              if (item.type == 'checkbox') {
                return (
                  <Form.Item
                    key={`${index}`}
                    label={item.label}
                    name={item.label}
                    labelAlign="left"
                    // rules={[{ required: true, message: 'Cannot be empty' }]}
                  >
                    <Checkbox.Group>
                      <Checkbox value={item.label} key={`${index}`}>
                        <label style={{ fontSize: '14px' }}>{item.label}</label>
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                );
              }
              if (item.type == 'date') {
                return (
                  <Form.Item
                    key={`${index}`}
                    label={item.label}
                    name={item.label}
                    labelAlign="left"
                    rules={[{ required: true, message: 'Cannot be empty' }]}
                  >
                    <DatePicker style={{ width: '100%' }} suffixIcon={null} />
                  </Form.Item>
                );
              }
            })}
          </Form>
          <div style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>Signed Date: {moment().format('Do MMM YYYY')}</span>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default WaiverModal;
