import React, { useState, useEffect } from 'react';
import { Row, Col, notification } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import VideoPlayerComp from '../../../component/VideoPlayerComp/VideoPlayerComp';
import ChatBox from '../../../component/ChatBox/ChatBox';
import RelatedVideosComp from 'component/RelatedVideosComp/RelatedVIdeosComp';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { VIEWED_VIDEO_MUTATION } from 'apollo/video/mutation';
import { VIDEO_QUERY } from 'apollo/video/queries/video';
import LoadingSpinner from 'component/Loader';
import VideoComments from 'component/VideoComments/VideoComments';
import './../../../component/Layout/Header/notificationStyles.css';

// import { GApageView } from 'helper/reactga';

const Video: React.FC<any> = () => {
  const location = useLocation();
  const history = useHistory();
  const [videoId, setVideoId] = useState<null | String>(null);
  const [viewedVideo] = useMutation(VIEWED_VIDEO_MUTATION);

  const getVideoId = () => {
    let videoIdParam: null | String = null;
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      videoIdParam = queryParams.get('vid');
      if (videoIdParam == null) {
        return null;
      }
    }
    return videoIdParam;
  };
  const { error, loading, data, refetch } = useQuery(VIDEO_QUERY, {
    variables: { _id: getVideoId() },
    onCompleted: res => {
      if (res.video == null) {
        history.push('/');
      }
    },
    onError: (err: any) => {
      console.log('erssssssr', err);
      const errMessage = err.toString().replace('Error: GraphQL error: ', '');
      history.push('/');
      if (errMessage == 'You do not have package to watch this video.') {
        notification.error({ message: errMessage, className: 'notification' });
      }
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
  useEffect(() => {
    if (data?.video) {
      let videoIdParam = getVideoId();
      setVideoId(videoIdParam);
      if (videoIdParam) {
        viewedVideo({ variables: { _id: data?.video?._id } })
          .then(data => console.log('succes'))
          .catch(err => console.log('err'));
      } else {
        console.log('heree', videoIdParam);
        history.push('/');
      }
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;

  return (
    <div>
      {data && data.video && (
        <Row style={{ margin: '0 auto', maxWidth: '1680px', width: '98%' }}>
          <Col lg={18} style={{ marginTop: '15px', width: '100%', padding: '0 0.5em' }}>
            {data.video.status == 'published' ? (
              <>
                <VideoPlayerComp videoDetails={data.video} />
                {data.video.allowComments ? (
                  <VideoComments videoId={data.video._id} videoDetails={data.video} />
                ) : (
                  <div style={{ background: 'white', marginTop: 15, padding: '1em 1em', textAlign: 'center' }}>
                    <span style={{ fontSize: '1.5rem' }}>Comments are disabled.</span>
                  </div>
                )}
              </>
            ) : (
              <span>Video Not Available</span>
            )}
          </Col>
          <Col lg={6} style={{ marginTop: '15px', padding: '0 0.5em', width: '100%' }}>
            <div style={{ width: '100%' }}>
              {data.video.isLive && false && (
                <div style={{ marginBottom: 15 }}>
                  <ChatBox />
                </div>
              )}
              <div style={{ width: '100%' }}>
                <RelatedVideosComp videoDetails={data.video} refetch={refetch} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Video;
