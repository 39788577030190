import React, { useState } from 'react';
import Layout from '../../component/Layout';
import { Switch, Route } from 'react-router-dom';
import Error404 from '../Error404';
import VideoList from '../../view/Main/VideoList';
import Video from '../../view/Main/Video';
import Home from '../../view/Main/Home';
import SearchResults from '../../view/Main/SearchResults';

interface Props {}

const ProtectedRoutes = (props: Props) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState<any>(null);
  // const [load, setLoad] = useState(true);

  // useEffect(() => {
  //     const getMenuItem = () => {
  //         instance.get('/services')
  //             .then(data => {
  //                 setMenu(data.data);
  //                 setLoad(false);
  //             })
  //             .catch(err => {
  //                 setLoad(true);
  //                 console.log(err);
  //             });
  //     }
  //     // getMenuItem();
  //     return () => {
  //         setLoad(false);
  //     }
  // }, []);

  // if (load) return <LoadingSpinner />;

  return (
    <Layout menu={[]} setShowFilterModal={setShowFilterModal}>
      <Switch>
        <Route exact path="/">
          <Home showFilterModal={showFilterModal} setShowFilterModal={setShowFilterModal} filtersMain={filters} setFilters={setFilters} />
        </Route>
        <Route path="/videolist/:activity">
          <VideoList showFilterModal={showFilterModal} setShowFilterModal={setShowFilterModal} filtersMain={filters} setFilters={setFilters} />
        </Route>
        <Route path="/search" component={SearchResults} />
        <Route path="/video" component={Video} />
        <Route component={Error404} />
      </Switch>
    </Layout>
  );
};

export default ProtectedRoutes;
