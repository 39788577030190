import { gql } from 'apollo-boost';

export const USER_FROM_TOKEN_QUERY = gql`
  query userFromToken {
    userFromToken {
      username
      firstName
      lastName
      userId
      avatarUrl
      organizationId
    }
  }
`;
