import React, { useState } from 'react';
import { List } from 'antd';

import CommentComp from 'component/CommentComp/CommentComp';

const CommentList = ({ comments, setComments, canReply, videoDetails }) => {
  const onDelete = index => {
    let temp = [...comments];
    temp.splice(index, 1);
    setComments(temp);
  };
  const onEdit = (comment, index) => {
    let temp = [...comments];
    temp[index] = comment;
    setComments(temp);
  };
  return (
    <List
      className="comment-list"
      itemLayout="horizontal"
      dataSource={comments}
      renderItem={(item: any, index: any) => (
        <li>
          <CommentComp commentItem={item} canReply={canReply} videoDetails={videoDetails} commentIndex={index} onDelete={onDelete} onEdit={onEdit} />
        </li>
      )}
    />
  );
};

export default CommentList;
