import React from 'react';
import { ThemeProvider } from 'styled-components';

// const theme = {
//   colors: {
//     primary: '#00205B',
//     secondary: '#4EC3E0',
//     darkGrey: '#191919',
//     lightGrey: '#cccccc',
//   },
//   fonts: ['Gotham', 'Roboto'],
//   fontSizes: {
//     small: '0.75rem',
//     medium: '1rem',
//     large: '1.25rem',
//   },
// };

const Theme = ({ theme, children }) => (
  <ThemeProvider
    theme={{
      colors: {
        primary: (theme && theme.colors && theme.colors.primary) || '#00205B',
        secondary: (theme && theme.colors && theme.colors.secondary) || '#4EC3E0',
        darkGrey: '#191919',
        lightGrey: '#cccccc',
      },
      fonts: ['Gotham', 'Roboto'],
      fontSizes: {
        small: '0.75rem',
        medium: '1rem',
        large: '1.25rem',
      },
    }}
  >
    {children}
  </ThemeProvider>
);

export default Theme;
