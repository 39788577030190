import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { removeToken, removeUserData, getUserData, getToken, getOrganizationData, getIsAppUser } from '../../../helper';
import { Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { USER_FROM_TOKEN_QUERY } from 'apollo/user/queries';
import { WANT_ACCESSES_QUERY } from 'apollo/others/queries';
import LoadingSpinner from 'component/Loader';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const CardWrapper = styled.div`
  width: 100%;
`;

const CardTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.darkGrey};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  margin: 1em 0;
  cursor: pointer;
  border: 2px solid ${(props: any) => props.theme.colors.lightGrey};
  border-radius: 0.5rem;
  span {
    margin: 0;
  }
  &:hover {
    color: ${(props: any) => props.theme.colors.primary};
    border: 2px solid ${(props: any) => props.theme.colors.darkGrey};
  }
`;
const LoginText = styled.p`
  font-size: ${(props: any) => props.theme.fontSizes.small};
  color: ${(props: any) => props.theme.colors.primary};
  font-weight: 300;
  text-align: left;
  margin: 0;
  cursor: pointer;
  &:hover {
    color: ${(props: any) => props.theme.colors.secondary};
  }
`;
interface Props { }

const WantAccess = (props: Props) => {
  const [organization] = useState(JSON.parse(getOrganizationData()));
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [wantAccesses, setWantAccesses] = useState<any>(null);
  const [userFromToken] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
    onCompleted: data => {
      setUser(data.userFromToken);
    },
  });
  // const onClick = type => {
  //   let url = '';
  //   if (type == '1DayPass') url = 'https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=membership&id=89511';
  //   if (type == 'videoMembership') url = ' https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=membership&id=89512';
  //   window.location.assign(url);
  // };
  const onClick = (url, newTab) => {
    // let url = '';
    // if (type == '1DayPass') url = 'https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=membership&id=89511';
    // if (type == 'videoMembership') url = ' https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=membership&id=89512';
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.assign(url);
    }
  };

  useQuery(WANT_ACCESSES_QUERY, {
    variables: { organizationId: organization._id },
    onCompleted: data => {
      setWantAccesses(data.wantAccesses || []);
    },
    onError: err => {
      console.error('want access err', err);
      setWantAccesses([]);
    },
  });

  useEffect(() => {
    if (getToken() && !JSON.parse(getUserData())) {
      userFromToken();
    }
    return () => { };
  }, []);

  const goBackLogin = async () => {
    await removeToken();
    await removeUserData();
    window.location.href = `${window.location.origin}/auth/login`;
  };

  return (
    <>
      {wantAccesses ? (
        <LoginWrapper>
          {
            !(getIsAppUser('is_app_user') === 'yes') && (
              <div style={{ width: '80%', maxWidth: '300px', marginBottom: '4em' }}>
                <img
                  src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
                  style={{ objectFit: 'contain', width: '100%', maxHeight: '200px' }}
                  alt="organization logo"
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
                  }}
                />
              </div>
            )
          }
          <h1 style={{ textAlign: 'center' }}>Video Classes and Library</h1>
          <CardWrapper>
            <Card
              title={
                <CardTitle>
                  Welcome
                  {user && !!user.username
                    ? ` ${user.username}`
                    : user && !!user.firstName && !!user.lastName
                      ? ` ${user.firstName[0].toUpperCase() + user.firstName.slice(1)} ${user.lastName[0].toUpperCase() + user.lastName.slice(1)}`
                      : ''}
                </CardTitle>
              }
              style={{
                maxWidth: '400px',
                minWidth: '320px',
                width: '90%',
                borderRadius: '5px',
                backgroundColor: '#fff',
                margin: '0 auto',
                boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.3)',
              }}
            >
              <h2 style={{ textAlign: 'center', fontSize: '1.15rem' }}>Want Access?</h2>
              <ButtonsWrapper>
                {/* <StyledButton>
              <span style={{ fontSize: '1.125rem' }}>1 Week Trial</span>
              <RightOutlined style={{ fontSize: '1.5rem', margin: 0 }} />
            </StyledButton> */}
                {wantAccesses &&
                  wantAccesses.map(item => {
                    return (
                      <StyledButton key={item._id} onClick={() => onClick(item.url, item.newTab)}>
                        <span style={{ fontSize: '1.125rem' }}>{item.title}</span>
                        <RightOutlined style={{ fontSize: '1.5rem', margin: 0 }} />
                      </StyledButton>
                    );
                  })}
                {/* <StyledButton onClick={() => onClick('1DayPass')}>
                  <span style={{ fontSize: '1.125rem' }}>1 Day Pass</span>
                  <RightOutlined style={{ fontSize: '1.5rem', margin: 0 }} />
                </StyledButton>
                <StyledButton onClick={() => onClick('videoMembership')}>
                  <span style={{ fontSize: '1.125rem' }}>Video Membership</span>
                  <RightOutlined style={{ fontSize: '1.5rem', margin: 0 }} />
                </StyledButton> */}
              </ButtonsWrapper>
              {
                !(getIsAppUser('is_app_user') === 'yes') && (
                  <div style={{ textAlign: 'left' }}>
                    <LoginText onClick={goBackLogin}>Go to Login Page?</LoginText>
                  </div>
                )
              }
            </Card>
          </CardWrapper>
        </LoginWrapper>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default WantAccess;
