import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import PrivateRoutes from './PrivateRoutes';
import Login from '../view/Auth';
import Error404 from './Error404';
import { getToken } from '../helper';
import WantAccess from 'view/Main/WantAccess';
import ChangePassword from 'view/Main/ChangePassword';
import ForgotPassword from 'view/Main/ForgotPassword';
import SignatureLogin from 'view/Auth/SignatureLogin/SingatureLogin';
import AppVideoLogin from 'view/Auth/AppVideoLogin/AppVideoLogin';

interface Props {}

const RouterPath: React.FC<Props> = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/applogin">{getToken() ? <Redirect to="/" /> : < AppVideoLogin />}</Route>
        <Route path="/login">{getToken() ? <Redirect to="/" /> : <Login />}</Route>
        <Route path="/wantaccess" component={WantAccess} />
        <Route path="/signaturelogin" component={SignatureLogin} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/changepassword" component={ChangePassword} />
        <PrivateRoutes path="/" component={ProtectedRoutes} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterPath;
