import React from 'react';
import { Modal } from 'antd';

const IframeModal = ({ visible, onCancel, payload }) => {
  if (payload) {
    return (
      <Modal
        title={payload.title}
        visible={visible}
        onCancel={onCancel}
        footer={[]}
        bodyStyle={{ width: '100%' }}
        style={{ maxWidth: '1000px' }}
        width="95%"
      >
        <iframe
          id="iframenew"
          src={payload.url}
          style={{ width: '100%', height: '500px' }}
          title="sadas"
          is="x-frame-bypass"
          // onLoad={() => aasd()}
        ></iframe>
      </Modal>
    );
  } else {
    return null;
  }
};

export default IframeModal;
