import { notification } from 'antd';
import axios from 'axios';
import LoadingSpinner from 'component/Loader/LoadingSpinner';
import { setIsAppUser, setToken, setUserData } from 'helper';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';


const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:'center';
  padding: 50px 20px;
`;


interface Props { }

const AppVideoLogin = (props: Props) => {
    const history = useHistory();
    const location = useLocation();
    const [userToken, setUserToken] = useState<any>();
    let queryParams = new URLSearchParams(location.search);
    let sessionExpired = queryParams.get('sessionExpired') === '1';
    let redirectUri: any = queryParams.get('redirect');
    const loginUser = async () => {
        let token = queryParams.get('token');
        if (!token) {
            return false;
        }
        try {
            setUserToken(token)
            const requestData = {
                operationName: 'appVideoLogin',
                variables: {},
                query: `mutation appVideoLogin {
                  appVideoLogin
                }`
            };
            const localUrl = 'http://192.168.1.70:5000/graphql'
            const config = {
                url: 'https://traffic.tpasc.ca/graphql',
                method: 'post',
                headers: {
                    'accept': '*/*',
                    'accept-language': 'en-GB,en;q=0.9,es-ES;q=0.8,es;q=0.7,en-US;q=0.6',
                    'authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
                data: JSON.stringify(requestData),
                // withCredentials: true // Include credentials if necessary
            };
            const res = await axios(config);
            if (res && res?.data?.data?.appVideoLogin?.jwt) {
                const { jwt, ...rest } = res?.data?.data?.appVideoLogin;
                const { user } = rest;
                await setToken(jwt);
                await setUserData(JSON.stringify(user));
                await setIsAppUser('yes');
                if (redirectUri) {
                    history.push(`/${redirectUri}`);
                } else {
                    history.push('/')
                }
            } else {
                throw new Error("Error accured on video loading.")
            }
        } catch (error) {
            notification.error({
                message: 'Error accured on video loading.',
            });
        }
    }
    useEffect(() => {
        if (sessionExpired) {
            notification.error({
                message: 'You have logged in on another device or your session has expired.',
            });
        }
        loginUser();
    }, [sessionExpired])

    return (
        <LoginWrapper>
            {(sessionExpired || !userToken) ? (
                <div>
                    <p>You have logged in on another device or your session has expired.</p>
                </div>
            ) : <LoadingSpinner />
            }
        </LoginWrapper>
    );
};

export default AppVideoLogin;
