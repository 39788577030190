import React, { useState, useEffect } from 'react';
import { Input, Form, Card, notification, Modal } from 'antd';
import styled from 'styled-components';
import { getOrganizationData, removeToken, removeUserData } from 'helper';
import { useLocation, useHistory } from 'react-router-dom';
import AntdButton from 'component/elements/AntdButton';
import { ExclamationCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { CHECK_EMAIL_FORGOT_PW_MUTATION } from 'apollo/user/mutations';
import IframeModal from 'component/IframeModal/IframeModal';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const FormTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.darkGrey};
`;

const ChangePassword = () => {
  const location = useLocation();
  const history = useHistory();
  const [organization] = useState(JSON.parse(getOrganizationData()));
  const [form] = Form.useForm();
  const [token, setToken] = useState<any>(null);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined);
  const [err, setErr] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>();
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [payload, setPayload] = useState<any>(null);
  const [checkEmailForgotPassword] = useMutation(CHECK_EMAIL_FORGOT_PW_MUTATION);
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let token = queryParams.get('token');
      // console.log('token ', token);
      setToken(token);
    }
  }, [location]);
  const onChange = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };
  const closeAndReset = () => {
    form.setFieldsValue({ email: undefined });
    setErr(null);
  };

  const handleSubmit = () => {
    setLoading(true);
    setErr(null);
    form
      .validateFields()
      .then(done => {
        checkEmailForgotPassword({
          variables: {
            email: done.email,
            organizationId: organization._id,
          },
        })
          .then(async result => {
            if (result.data && result.data.checkEmailForgotPasswordSubscriber) {
              const { type, payload } = result.data.checkEmailForgotPasswordSubscriber;
              if (type == 'iframe') {
                if (payload) {
                  closeAndReset();
                  setPayload(payload);
                  setShowIframeModal(true);
                } else {
                  notification.error({
                    message: type,
                    description: 'No Url provided by location. Contact Your location.',
                  });
                }
              } else if (type == 'redirect') {
                if (payload) {
                  closeAndReset();
                  Modal.confirm({
                    title: 'Redirected',
                    icon: <ExclamationCircleOutlined />,
                    content: 'You will be redirected to password change page. Do you want to continue?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: e => {
                      window.location.assign(payload);
                    },
                  });
                } else {
                  notification.error({
                    message: type,
                    description: 'No Url provided by location. Contact Your location.',
                  });
                }
              } else if (type == 'message') {
                notification.info({
                  message: type,
                  description: payload,
                });
                history.push('/login');
              }
            }
            setLoading(false);
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
              setLoading(false);
            }
          });
      })
      .catch(err => {
        console.log('err', err);
        setLoading(false);
      });
  };
  return (
    <LoginWrapper>
      <div style={{ width: '80%', maxWidth: '300px', marginBottom: '4em' }}>
        <img
          src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
          }}
          style={{ objectFit: 'contain', width: '100%', maxHeight: '200px' }}
          alt="organization logo"
        />
      </div>
      <FormWrapper>
        <Card
          title={<FormTitle>Forgot Password</FormTitle>}
          style={{
            maxWidth: '400px',
            minWidth: '320px',
            width: '90%',
            borderRadius: '5px',
            backgroundColor: '#fff',
            margin: '0 auto',
            boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.3)',
          }}
        >
          <div>
            <p>Enter your registerd email address</p>
          </div>
          <p style={{ color: '#f00', marginBottom: '10px' }}>
            <strong>{err}</strong>
          </p>
          <Form layout="vertical" form={form} name="login">
            <Form.Item
              label="Email"
              name="email"
              labelAlign="left"
              rules={[
                { required: true, message: 'Enter your registered email' },
                { type: 'email', required: true, message: 'Enter valid email address' },
              ]}
            >
              <Input
                onPressEnter={handleSubmit}
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Enter email"
                onChange={e => setErr('')}
              />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AntdButton type="primary" onClick={handleSubmit} loading={loading} style={{ marginLeft: '1em' }}>
                Submit
              </AntdButton>
            </div>
          </Form>
        </Card>
      </FormWrapper>
      <IframeModal
        visible={showIframeModal}
        onCancel={() => {
          setShowIframeModal(false);
          setPayload(null);
        }}
        payload={payload}
      />
    </LoginWrapper>
  );
};

export default ChangePassword;
