import { gql } from 'apollo-boost';
import { COMMENT_FRAGMENT } from '../commentFragment';

export const UPDATE_COMMENT_MUTATION = gql`
  mutation updateComment($input: CommentUpdateInput!, $_id: ID!) {
    updateComment(_id: $_id, input: $input) {
      ...Comment
    }
  }
  ${COMMENT_FRAGMENT}
`;
