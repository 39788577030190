import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './styles.css';

const SkeletonCard = () => {
  const menu = [1, 2, 3, 4, 5].map((item) => {
    return (
      <div key={item} className="card-wrapper">
        <div className="card-image">
          <Skeleton height={`100%`} width={`100%`} />
        </div>
        <div className="card-title">
          <div style={{ width: '10%', minWidth: 45 }}>
            <Skeleton circle={true} height={45} width={45} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.7rem', width: '90%' }}>
            <Skeleton height={10} width={`90%`} />
            <Skeleton height={10} width={`70%`} />
            <Skeleton height={10} width={`70%`} />
          </div>
        </div>
      </div>
    );
  });
  return (
    <SkeletonTheme color="#AAACA9" highlightColor="#c1c1c1">
      <h2 className="section-title">
        <Skeleton duration={1} height={`3rem`} width={`100%`} />
      </h2>
      <ScrollMenu
        alignCenter={false}
        // arrowLeft={<Arrow text="left" />}
        // arrowRight={<Arrow text="right" />}
        clickWhenDrag={false}
        data={menu}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        // onFirstItemVisible={this.onFirstItemVisible}
        // onLastItemVisible={this.onLastItemVisible}
        // onUpdate={onUpdate}
        scrollToSelected={false}
        transition={1}
        translate={0}
        itemStyle={{ outline: 'none' }}
        wheel={false}
      />
    </SkeletonTheme>
  );
};

export default SkeletonCard;
