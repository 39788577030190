import { gql } from 'apollo-boost';

export const GUEST_LOGIN = gql`
  mutation guestLogin($organizationId: String!) {
    guestLogin(organizationId: $organizationId) {
      jwt
      user {
        username
        firstName
        lastName
        userId
        avatarUrl
        organizationId
      }
    }
  }
`;
