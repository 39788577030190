import { gql } from 'apollo-boost';

export const WANT_ACCESSES_QUERY = gql`
  query wantAccesses($organizationId: String!) {
    wantAccesses(organizationId: $organizationId) {
      _id
      title
      url
      newTab
    }
  }
`;
