import { gql } from 'apollo-boost';

export const EZ_CLIENT_LOGGN = gql`
  mutation ezClientlogin($username: String!, $password: String!) {
    ezClientlogin(username: $username, password: $password) {
      username
      firstName
      lastName
      jwt
      ClientID
      MembershipType
      MemberPhotoUrl
    }
  }
`;
