import React, { useContext } from 'react';
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { VideoTitle, InfoWrapper } from './VideoItemStyles';
import viewFormatter from 'helper/viewsFormatter';
import { ThemeContext } from 'styled-components';

const VideoItem = ({ onClick, item }) => {
  const themeContext = useContext(ThemeContext);
  let views = '0';
  if (item.totalViews) {
    views = viewFormatter(item.totalViews, 1);
  }
  return (
    <Card
      onClick={() => (item.premiereAt && item.vid == null ? {} : onClick(item))}
      style={{
        cursor: item.premiereAt && item.vid == null ? 'default' : 'pointer',
        background: 'transparent',
      }}
      bodyStyle={{ padding: '0em' }}
    >
      <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
        <img
          alt="video preview"
          src={
            item.thumbnail ||
            (item.organization && item.organization.settings && item.organization.settings.fallbackThumbnail) ||
            `https://via.placeholder.com/1000/FFFFFF/000000/?text=${item.organization.name}`
          }
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${item.organization.name}`;
          }}
          style={{
            background: '#fff',
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
          draggable={false}
        />
        {item.duration && (
          <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.7)', borderRadius: 3, bottom: 0, right: 0, padding: '0 5px', margin: 5 }}>
            <span style={{ color: 'white' }}>
              {new Date(item.duration * 1000)
                .toISOString()
                .substr(11, 8)
                .replace(/^[0:]+/, '')}
            </span>
          </div>
        )}
        {item.watched && (
          <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.7)', borderRadius: 3, bottom: 0, left: 0, padding: '0 5px', margin: 5 }}>
            <span style={{ color: 'white' }}>Watched</span>
          </div>
        )}
      </div>
      <InfoWrapper>
        <div>
          <Avatar size={45} icon={<UserOutlined />} src={item.trainer && item.trainer.trainerPicUrl} />
        </div>
        <div style={{ marginLeft: '0.7em' }}>
          <VideoTitle style={{ margin: 0, padding: 0 }}>
            {item.title ||
              `${item.activity.name} - ${
                item.premiereAt ? moment(item.premiereAt).format('MMM D, YYYY') : moment(item.createdAt).format('MMM D, YYYY')
              }`}
          </VideoTitle>
          <p style={{ margin: 0, padding: 0, fontSize: '1.25rem', fontWeight: 300, lineHeight: '1.5rem' }}>
            {(item.trainer && item.trainer.name) || 'Trainer Name'} <span>&#183;</span> {(item.activity && item.activity.name) || 'Activity'}
          </p>
          <p style={{ margin: 0, padding: 0, fontSize: '1.25rem', fontWeight: 300, lineHeight: '1.5rem' }}>
            {item.premiereAt ? moment(item.premiereAt).fromNow() : moment(item.createdAt).fromNow()}
          </p>
          {item.premiereAt && item.vid == null && (
            <p style={{ margin: 0, padding: 0, fontSize: '1rem', fontWeight: 300 }}>{`Premiere at ${moment(item.premiereAt).format(
              'DD/MM/YYYY hh:mm a'
            )}`}</p>
          )}
          {item.isLive && (
            <span
              style={{
                margin: 0,
                padding: '0.2em',
                fontSize: '1rem',
                fontWeight: 400,
                color: '#FF4E45',
                borderRadius: 2,
                lineHeight: '1rem',
                border: '1px solid #FF4E45',
              }}
            >
              Live Now
            </span>
          )}
          {!!item.parentOrganizationId && item.parentOrganization && !!item.parentOrganization.name && (
            <span
              style={{
                margin: 0,
                padding: '0.2em',
                fontSize: '1rem',
                fontWeight: 400,
                color: themeContext.colors.primary,
                borderRadius: 2,
                lineHeight: '1rem',
                border: `1px solid ${themeContext.colors.primary}`,
              }}
            >
              {`${item.parentOrganization.name}`}
            </span>
          )}
          {item.recentPremiere && item.premiereAt && (
            <span
              style={{
                margin: 0,
                padding: '0.2em',
                fontSize: '1rem',
                fontWeight: 400,
                color: '#4EC3E0',
                borderRadius: 2,
                lineHeight: '1rem',
                border: '1px solid #4EC3E0',
              }}
            >{`Premiered at ${moment(item.premiereAt).format('MMM DD,YYYY hh:mm a')}`}</span>
          )}
        </div>
      </InfoWrapper>
    </Card>
  );
};

export default VideoItem;
