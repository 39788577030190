import React from 'react';
import { Modal } from 'antd';

const TrainerDetailModal = ({ visible, onCancel, trainerDetailUrl }) => {
  return (
    <Modal
      title="Trainer Detail"
      visible={visible}
      onCancel={onCancel}
      footer={[]}
      bodyStyle={{ width: '100%' }}
      style={{ maxWidth: '1000px' }}
      width="95%"
    >
      <iframe
        id="iframenew"
        src={`${trainerDetailUrl}`}
        style={{ width: '100%', height: '500px' }}
        title="sadas"
        is="x-frame-bypass"
        // onLoad={() => aasd()}
      ></iframe>
    </Modal>
  );
};

export default TrainerDetailModal;
