import React from 'react';
import { List } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { RELATED_VIDEOS_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import VideoItem from 'component/VideoItem/VideoItem';
import { useHistory } from 'react-router-dom';

const RelatedVideosComp = ({ videoDetails, refetch }) => {
  const history = useHistory();
  const styledContent = document.getElementById('styledContent');
  const { loading, error, data } = useQuery(RELATED_VIDEOS_QUERY, {
    variables: {
      _id: videoDetails._id,
      activity: videoDetails.activity._id,
    },
  });
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('related', data);
  // }

  const onClickItem = (item) => {
    // console.log('item clicker', item.title);

    history.push({
      pathname: '/video',
      search: `?vid=${item._id}`,
    });
    // styledContent?.scrollTo(0, 0);
    refetch({
      _id: item._id,
    });
  };

  return (
    <>
      {data.relatedVideos && (
        <>
          <h1>Related Videos</h1>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 1,
              xxl: 1,
            }}
            dataSource={data && data.relatedVideos}
            renderItem={(item) => (
              <List.Item>
                <VideoItem onClick={onClickItem} item={item} />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
};

export default RelatedVideosComp;
