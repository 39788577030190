import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from 'apollo/trainer/trainerFragment';

export const VIDEO_FRAGMENT = gql`
  fragment Video on Video {
    _id
    isLive
    url
    thumbnail
    vid
    title
    description
    tags
    activity {
      _id
      name
    }
    trainer {
      ...Trainer
    }
    organizationId
    organization {
      _id
      name
      settings
      logo
    }
    premium
    recentPremiere
    premiere
    premiereAt
    totalViews
    duration
    intensity
    equipments
    allowComments
    parentVideoId
    parentVideo {
      _id
      title
    }
    parentOrganizationId
    parentOrganization {
      _id
      name
      logo
    }
    introUrl
    status
    size
    captionUrl
    watched
    createdAt
    updatedAt
  }
  ${TRAINER_FRAGMENT}
`;
