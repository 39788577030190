import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AntdButton from '../../../component/elements/AntdButton';
import { getOrganizationData, setIsAppUser, setToken, setUserData } from '../../../helper';
import { Form, Card, Input, Button, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GUEST_LOGIN, EXTERNAL_CLIENT_LOGIN } from 'apollo/user/mutations';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ForgotPwModal from 'component/ForgotPwModal/ForgotPwModal';
import IframeModal from 'component/IframeModal/IframeModal';
import './../../../component/Layout/Header/notificationStyles.css';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const FormTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.darkGrey};
`;
const InfoText = styled.p`
  font-size: ${(props: any) => props.theme.fontSizes.small};
`;

const ForgotPassword = styled.a`
  font-size: ${(props: any) => props.theme.fontSizes.small};
  color: ${(props: any) => props.theme.colors.primary};
  font-weight: 300;
  text-align: right;
`;

interface Props { }

const LoginForm = (props: Props) => {
  // const [showForgotPwModal, setShowForgotPwModal] = useState(false);
  // const [showIframeModal, setShowIframeModal] = useState(false);
  // const [payload, setPayload] = useState<any>(null);
  const [organization] = useState(JSON.parse(getOrganizationData()));
  const [form] = Form.useForm();
  const [loginUser] = useMutation(EXTERNAL_CLIENT_LOGIN);
  const [loginGuest] = useMutation(GUEST_LOGIN);
  const history = useHistory();
  const location = useLocation();

  const setLoginToken = async token => {
    await setToken(token);
    window.location.reload();
  };
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let token = queryParams.get('token');
      let sessionExpired = queryParams.get('sessionExpired');
      if (sessionExpired == '1') {
        notification.error({
          message: 'You have logged in on another device or your session has expired.',
        });
      }
      if (token) {
        // console.log('token ', token);
        setLoginToken(token);
      }
    }
  }, [location]);

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [err, setErr] = useState<string>();
  const [load, setLoad] = useState<boolean>();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleLoginInput = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };
  const loginAsGuest = () => {
    setLoad(true);
    loginGuest({
      variables: {
        organizationId: organization._id,
      },
    })
      .then(async result => {
        // console.log('done>>>>>>', result);
        if (result.data && result.data.guestLogin) {
          const { jwt, user } = result.data.guestLogin;
          await setToken(jwt);
          await setIsAppUser('No')
          await setUserData(JSON.stringify(user));
        }
        window.location.reload();
        setLoad(false);
      })
      .catch(error => {
        if (error.graphQLErrors && error.graphQLErrors.length) {
          console.log('errssss', error.graphQLErrors);
          const errMessage = error.graphQLErrors[0].message;
          setErr(errMessage);
          setLoad(false);
        }
      });
  };
  const handleLogin = () => {
    setLoad(true);
    form
      .validateFields()
      .then(done => {
        loginUser({
          variables: {
            username: username,
            password: password,
            organizationId: JSON.parse(getOrganizationData())._id,
          },
        })
          .then(async result => {
            // console.log('done>>>>>>', result);
            if (result.data && result.data.externalClientLogin) {
              const { jwt, user } = result.data.externalClientLogin;
              await setToken(jwt);
              await setIsAppUser('No')
              await setUserData(JSON.stringify(user));
            }
            window.location.reload();
            setLoad(false);
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              console.log('errssss', error.graphQLErrors);
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
              setLoad(false);
            }
          });
      })
      .catch(err => {
        console.log('err', err);
        setLoad(false);
      });
  };
  const incompleteRegistrationError = (
    <span>
      Your username and password is correct but you registration is incomplete. Please check your email to complete registration or visit this link (
      <a href="https://tpasc.ezfacility.com">tpasc.EZFacility.com</a>) and sign in to get a new email confirmation.
    </span>
  );

  return (
    <LoginWrapper>
      <div style={{ width: '80%', maxWidth: '300px', marginBottom: '4em' }}>
        <img
          src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
          }}
          style={{ objectFit: 'contain', width: '100%', maxHeight: '200px' }}
          alt="organization logo"
        />
      </div>
      <h1 style={{ textAlign: 'center' }}>{(organization.customData && organization.customData.loginPageTitle) || 'Video Classes and Library'}</h1>
      <FormWrapper>
        <Card
          title={<FormTitle>Login</FormTitle>}
          style={{
            maxWidth: '400px',
            minWidth: '320px',
            width: '90%',
            borderRadius: '5px',
            backgroundColor: '#fff',
            margin: '0 auto',
            boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.3)',
          }}
        >
          {/* <InfoText>Please login with your TPASC EzFacility Username and password that you use to access our self service</InfoText> */}
          <p style={{ color: '#f00', marginBottom: '10px' }}>
            <strong>{err == 'INCOMPLETE_REGISTRATION' ? incompleteRegistrationError : err}</strong>
          </p>
          <Form {...layout} form={form} name="login">
            <Form.Item label="Username" name="username" labelAlign="left" rules={[{ required: true, message: 'Enter your Username' }]}>
              <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={e => handleLoginInput(e.target.value, setUsername)} />
            </Form.Item>
            <Form.Item label="Password" name="password" labelAlign="left" rules={[{ required: true, message: 'Enter your password' }]}>
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onPressEnter={handleLogin}
                onChange={e => handleLoginInput(e.target.value, setPassword)}
              />
            </Form.Item>
            <div style={{ textAlign: 'right', marginBottom: '1em' }}>
              <ForgotPassword
                onClick={() => {
                  // setShowForgotPwModal(true);
                  history.push('forgotpassword');
                }}
              >
                Forgot your password?
              </ForgotPassword>
              <p style={{ fontSize: '0.5rem' }}>{organization.customData && organization.customData.forgotPasswordHelpText}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="default" onClick={() => history.push('/wantaccess')}>
                Sign Up
              </Button>
              <AntdButton type="primary" onClick={handleLogin} loading={load} style={{ marginLeft: '1em' }}>
                Login
              </AntdButton>
            </div>
            {organization?.allowGuestLogin && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                <Button type="text" style={{ padding: 0 }} onClick={loginAsGuest}>
                  Login As Guest
                </Button>
              </div>
            )}
          </Form>
        </Card>
      </FormWrapper>
      <p style={{ width: '100%', maxWidth: '600px', marginTop: '2em', textAlign: 'center' }}>
        {organization.customData && organization.customData.disclaimer}
      </p>
      {/* <IframeModal
        visible={showIframeModal}
        onCancel={() => {
          setShowIframeModal(false);
          setPayload(null);
        }}
        payload={payload}
      />
      <ForgotPwModal
        openIframeModal={url => {
          setPayload({ title: 'Forgot Password', url: url });
          setShowIframeModal(true);
        }}
        visible={showForgotPwModal}
        onDone={() => setShowForgotPwModal(false)}
      /> */}
    </LoginWrapper>
  );
};

// const Login = Form.create()(LoginForm);

export default LoginForm;
