import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  @media (min-width: 675px) {
    padding: 0 3em;
  }
`;
export const TwoFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 675px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const LabelWrapper = styled.div`
  margin-bottom: 0px;

  /* @media (min-width: 675px) {
    width: 28%;
    padding-right: 1em;
    text-align: right;
  } */
`;
export const InputWrapper = styled.div`
  width: 100%;
  @media (min-width: 675px) {
    width: 70%;
  }
`;
export const FilterWrapper = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;
