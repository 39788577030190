import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const ACTIVITIES_IN_DISPLAY_ORDER_QUERY = gql`
  query activitiesInDisplayOrder($organizationId: String) {
    activitiesInDisplayOrder(organizationId: $organizationId) {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
