import { gql } from 'apollo-boost';

export const CHECK_EMAIL_FORGOT_PW_MUTATION = gql`
  mutation checkEmailForgotPasswordSubscriber($email: String!, $organizationId: String) {
    checkEmailForgotPasswordSubscriber(email: $email, organizationId: $organizationId) {
      type
      payload
    }
  }
`;
