import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Collapse, Divider, List, Col, Row, Select } from 'antd';
import VideoItemHorizontal from '../../../component/VideoItemHorizontal/VideoItemHorizontal';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_FILTER_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import SearchFilterPanel from 'component/SearchFilterPanel/SearchFilterPanel';

const SearchResults = () => {
  const history = useHistory();
  const location = useLocation();

  const [searchString, setSearchString] = useState<String | null>(null);
  const [activity, setActivity] = useState('all');
  const [trainer, setTrainer] = useState('all');
  const [intensity, setIntensity] = useState('all');
  const [equipments, setEquipments] = useState([]);
  const [duration, setDuration] = useState(0);
  const [date, setDate] = useState(null);

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let searchQuery = queryParams.get('search_query');
    console.log('abc', searchQuery);
    setSearchString(searchQuery);
    return () => {};
  }, [location]);

  const { loading, error, data, refetch } = useQuery(VIDEOS_FILTER_QUERY, {
    variables: {
      videosFilterInput: {
        search: searchString,
      },
    },
  });

  const refetchQuery = (input) => {
    // console.log('input', input);
    refetch({
      videosFilterInput: { ...input },
    });
  };
  // if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('data', data);
  // }
  const onTrainerChange = (val) => {
    setTrainer(val);
    let input = {
      search: searchString,
      ...(val !== 'all' && { trainer: val }),
      ...(activity !== 'all' && { activity: activity }),
      ...(intensity !== 'all' && { intensity: intensity }),
      ...(equipments.length > 0 && { equipments: equipments }),
      ...(duration !== 0 && { duration: duration }),
    };
    // console.log(input);
    refetchQuery(input);
  };

  const onActivityChange = (val) => {
    setActivity(val);
    let input = {
      search: searchString,
      ...(trainer !== 'all' && { trainer: trainer }),
      ...(val !== 'all' && { activity: val }),
      ...(intensity !== 'all' && { intensity: intensity }),
      ...(equipments.length > 0 && { equipments: equipments }),
      ...(duration !== 0 && { duration: duration }),
    };
    refetchQuery(input);
  };
  const onIntensityChange = (val) => {
    setIntensity(val);
    let input = {
      search: searchString,
      ...(trainer !== 'all' && { trainer: trainer }),
      ...(activity !== 'all' && { activity: activity }),
      ...(val !== 'all' && { intensity: val }),
      ...(equipments.length > 0 && { equipments: equipments }),
      ...(duration !== 0 && { duration: duration }),
    };
    // console.log(input);
    refetchQuery(input);
  };
  const onDurationChange = (val) => {
    setDuration(val);
    let input = {
      search: searchString,
      ...(trainer !== 'all' && { trainer: trainer }),
      ...(activity !== 'all' && { activity: activity }),
      ...(intensity !== 'all' && { intensity: intensity }),
      ...(equipments.length > 0 && { equipments: equipments }),
      ...(val !== 0 && { duration: val }),
    };
    // console.log(input);
    refetchQuery(input);
  };
  const onEquipmentsChange = (val) => {
    setEquipments(val);
    let input = {
      search: searchString,
      ...(trainer !== 'all' && { trainer: trainer }),
      ...(activity !== 'all' && { activity: activity }),
      ...(intensity !== 'all' && { intensity: intensity }),
      ...(val.length > 0 && { equipments: val }),
      ...(duration !== 0 && { duration: duration }),
    };
    // console.log(input);
    refetchQuery(input);
  };
  const onDateChange = (val) => {
    setDate(val);
    let input = {
      search: searchString,
      ...(trainer !== 'all' && { trainer: trainer }),
      ...(activity !== 'all' && { activity: activity }),
      ...(intensity !== 'all' && { intensity: intensity }),
      ...(equipments.length > 0 && { equipments: equipments }),
      ...(duration !== 0 && { duration: duration }),
    };
    // console.log(input);
    refetchQuery(input);
  };

  const onClickItem = (item) => {
    // console.log('item clicker', item.title);
    history.push({
      pathname: '/video',
      search: `?vid=${item._id}`,
      state: { vid: item._id },
    });
  };

  return (
    <div style={{ paddingTop: '1em' }}>
      <div style={{ width: '95%', maxWidth: '1800px', margin: 'auto' }}>
        <SearchFilterPanel
          activity={activity}
          trainer={trainer}
          intensity={intensity}
          duration={duration}
          equipments={equipments}
          date={date}
          onActivityChange={onActivityChange}
          onTrainerChange={onTrainerChange}
          onIntensityChange={onIntensityChange}
          onEquipmentsChange={onEquipmentsChange}
          onDurationChange={onDurationChange}
          onDateChange={onDateChange}
        />
        {!loading ? (
          <div style={{ width: '95%', maxWidth: '1500px', margin: 'auto' }}>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1,
              }}
              style={{ marginTop: 15 }}
              dataSource={data.videosFilter.videos}
              renderItem={(item) => (
                <List.Item>
                  <VideoItemHorizontal onClick={onClickItem} item={item} />
                </List.Item>
              )}
            />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default SearchResults;
