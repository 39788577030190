import React, { useState, useEffect, FunctionComponent } from 'react';
import { Comment, Form, Input, Avatar, List, Button, message } from 'antd';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { MAKE_COMMENT_MUTATION, UPDATE_COMMENT_MUTATION } from 'apollo/comment/mutations';
import { UserOutlined } from '@ant-design/icons';
import { getUserData } from 'helper';
import { GAEvent } from 'helper/reactga';

const { TextArea } = Input;
const Editor = ({ onChange, onSubmit, onCancel, submitting, value, isEdit }) => (
  <>
    <Form.Item>
      <TextArea rows={2} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        <span style={{ fontSize: '1rem' }}>{isEdit ? 'Edit' : 'Comment'}</span>
      </Button>
      {isEdit && (
        <Button style={{ marginLeft: 10 }} onClick={onCancel}>
          <span style={{ fontSize: '1rem' }}>Cancel</span>
        </Button>
      )}
    </Form.Item>
  </>
);
const CommentBox: FunctionComponent<any> = ({
  videoDetails,
  videoId,
  parentId = null,
  comment = null,
  onCommentAdd,
  onCancel = () => {},
  onCommentEdit = () => {},
  children,
}) => {
  const user = JSON.parse(getUserData());
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [makeComment] = useMutation(MAKE_COMMENT_MUTATION);
  const [updateComment] = useMutation(UPDATE_COMMENT_MUTATION);

  useEffect(() => {
    if (comment) {
      setValue(comment.content);
      setIsEdit(true);
    }
  }, [comment]);

  const reset = () => {
    setValue('');
    setIsEdit(false);
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    if (isEdit) {
      setSubmitting(true);
      updateComment({
        variables: {
          _id: comment._id,
          input: {
            content: value,
          },
        },
      })
        .then(mutationResult => {
          // onCommentAdd({ ...mutationResult.data.makeComment, likes: [], dislikes: [], videoId: videoId });
          setSubmitting(false);
          reset();
          onCommentEdit({ ...comment, content: value });
          // console.log('here', mutationResult.data);
        })
        .catch(err => {
          setSubmitting(false);
          message.error('not success');
        });
    } else {
      let input = {
        videoId: videoId,
        content: value,
        ...(parentId != null && { parentId: parentId }),
      };
      setSubmitting(true);
      GAEvent('Videos', `Comment Video`, videoDetails.title, null, {
        dimension6: videoDetails.trainer.name,
        dimension5: videoDetails.title,
        dimension7: videoDetails.activity.name,
        dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
        dimension9: videoDetails.organizationId,
        dimension10: videoDetails._id,
        metric11: 1,
      });
      makeComment({
        variables: {
          input: input,
        },
      })
        .then(mutationResult => {
          onCommentAdd({ ...mutationResult.data.makeComment, likes: [], dislikes: [], videoId: videoId, userId: user.userId });
          setSubmitting(false);
          reset();
          // console.log('here', mutationResult.data);
        })
        .catch(err => {
          setSubmitting(false);
          message.error('not success');
        });
    }
  };
  const handleChange = e => {
    setValue(e.target.value);
  };
  return (
    <Comment
      avatar={<Avatar icon={<UserOutlined />} src={user.avatarUrl} />}
      content={
        <Editor onCancel={handleCancel} isEdit={isEdit} onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />
      }
    >
      {children}
    </Comment>
  );
};

export default CommentBox;
