import { gql } from 'apollo-boost';

export const EZ_CLIENT_SIGNATURE_LOGIN = gql`
  mutation ezClientSignatureLogin($clientId: String!, $memberId: String!, $organizationId: String!) {
    ezClientSignatureLogin(clientId: $clientId, memberId: $memberId, organizationId: $organizationId) {
      jwt
      user {
        username
        firstName
        lastName
        userId
        avatarUrl
        organizationId
      }
    }
  }
`;
