import { gql } from 'apollo-boost';
import { COMMENT_FRAGMENT } from '../commentFragment';

export const MAKE_COMMENT_MUTATION = gql`
  mutation makeComment($input: CommentInput!) {
    makeComment(input: $input) {
      ...Comment
    }
  }
  ${COMMENT_FRAGMENT}
`;
