import config from 'config';

/**
 * Logs out all the arguments with console.log
 * @param  {} ...args
 */
const logger = (...args) => {
  if (config.IS_CONSOLE_ALLOWED) {
    // console.log(...args); // eslint-disable-line
  }
  console.log(...args); // eslint-disable-line
};

export default logger;
