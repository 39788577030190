import React, { useState } from 'react';
import { Collapse, Col, Row, Select, DatePicker } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { FilterWrapper, LabelWrapper, TwoFilterWrapper, InputWrapper, MainWrapper } from './SearchFilterPanelStyles';

const { Panel } = Collapse;
const { Option } = Select;

const equipmentsList = [
  { label: 'Body Weight', key: 'bodyWeight' },
  { label: 'Dumbbells', key: 'dumbbells' },
];

const SearchFilterPanel = ({
  activity,
  trainer,
  intensity,
  equipments,
  duration,
  date,
  onActivityChange,
  onTrainerChange,
  onIntensityChange,
  onEquipmentsChange,
  onDurationChange,
  onDateChange,
}) => {
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);

  const renderEquipments = equipmentsList.map((item) => (
    <Option key={item.key} value={item.key}>
      {item.label}
    </Option>
  ));
  useQuery(TRAINERS_QUERY, {
    onCompleted: (data) => {
      setTrainers(data.trainers);
    },
    onError: (err) => {
      console.log('trainer err', err);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: (data) => {
      setActivities(data.activities);
    },
    onError: (err) => {
      console.log('activity err', err);
    },
  });

  return (
    <>
      {trainers && activities && (
        <Collapse>
          <Panel header="Filter" key="1">
            <MainWrapper>
              <TwoFilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Activity:</LabelWrapper>
                  <InputWrapper>
                    <Select style={{ width: '100%' }} value={activity} onChange={onActivityChange}>
                      <Option value="all">All</Option>
                      {activities &&
                        activities.map((item: any) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </InputWrapper>
                </FilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Trainer:</LabelWrapper>
                  <InputWrapper>
                    <Select style={{ width: '100%' }} value={trainer} onChange={onTrainerChange}>
                      <Option value="all">All</Option>
                      {trainers &&
                        trainers.map((item: any) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </InputWrapper>
                </FilterWrapper>
              </TwoFilterWrapper>
              <TwoFilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Intensity:</LabelWrapper>
                  <InputWrapper>
                    <Select style={{ width: '100%' }} value={intensity} onChange={onIntensityChange}>
                      <Option value="all">All</Option>
                      <Option value="low">Low</Option>
                      <Option value="medium">Medium</Option>
                      <Option value="high">high</Option>
                    </Select>
                  </InputWrapper>
                </FilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Equipments:</LabelWrapper>
                  <InputWrapper>
                    <Select mode="tags" style={{ width: '100%' }} value={equipments} placeholder="Select Equipment" onChange={onEquipmentsChange}>
                      {renderEquipments}
                    </Select>
                  </InputWrapper>
                </FilterWrapper>
              </TwoFilterWrapper>
              <TwoFilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Duration:</LabelWrapper>
                  <InputWrapper>
                    <Select style={{ width: '100%' }} value={duration} onChange={onDurationChange}>
                      <Option value={0}>All</Option>
                      <Option value={1800}>Less than 30 mins</Option>
                      <Option value={2700}>Less than 45 mins</Option>
                      <Option value={3600}>Greater than 60 mins</Option>
                    </Select>
                  </InputWrapper>
                </FilterWrapper>
                <FilterWrapper>
                  <LabelWrapper>Date:</LabelWrapper>
                  <InputWrapper>
                    <DatePicker value={date} style={{ width: '100%' }} onChange={onDateChange} />
                  </InputWrapper>
                </FilterWrapper>
              </TwoFilterWrapper>
            </MainWrapper>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default SearchFilterPanel;
