import React from 'react';
import { Card, Row, Col } from 'antd';
import moment from 'moment';
import { MainWrapper, VideoTitle } from './VideoItemNotificationStyles';
import viewFormatter from 'helper/viewsFormatter';

const VideoItemNotification = ({ item }) => {
  let views = '0';
  if (item.totalViews) {
    views = viewFormatter(item.totalViews, 1);
  }

  return (
    <MainWrapper>
      <div style={{ width: '40%', maxWidth: '450px' }}>
        <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
          <img
            alt="video preview"
            src={
              item.thumbnail ||
              (item.organization && item.organization.settings && item.organization.settings.fallbackThumbnail) ||
              `https://via.placeholder.com/1000/FFFFFF/000000/?text=${item.organization.name}`
            }
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${item.organization.name}`;
            }}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
              background: '#fff',
            }}
            draggable={false}
          />
        </div>
      </div>
      <div style={{ marginLeft: '1em' }}>
        <VideoTitle style={{ margin: 0, padding: 0 }}>
          {item.title ||
            `${item.activity.name} - ${
              item.premiereAt ? moment(item.premiereAt).format('MMM D, YYYY') : moment(item.createdAt).format('MMM D, YYYY')
            }`}
        </VideoTitle>
        <p style={{ margin: 0, padding: 0, fontSize: '1rem', fontWeight: 300, lineHeight: '1.2rem' }}>
          {(item.trainer && item.trainer.name) || 'Trainer Name'} <span>&#183;</span> {(item.activity && item.activity.name) || 'Activity'}
        </p>
        <p style={{ margin: 0, padding: 0, fontSize: '1rem', fontWeight: 300, lineHeight: '1.2rem' }}>
          Started {item.premiereAt ? moment(item.premiereAt).fromNow() : moment(item.createdAt).fromNow()}
        </p>
      </div>
    </MainWrapper>
  );
};

export default VideoItemNotification;
