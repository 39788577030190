import React, { useState, useEffect } from 'react';
import { Input, Form, Card, notification } from 'antd';
import styled from 'styled-components';
import { getOrganizationData, removeToken, removeUserData } from 'helper';
import { useLocation, useHistory } from 'react-router-dom';
import AntdButton from 'component/elements/AntdButton';
import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_PASSWORD_MUTATION } from 'apollo/user/mutations';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const FormTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.darkGrey};
`;

const ChangePassword = () => {
  const location = useLocation();
  const history = useHistory();
  const [organization] = useState(JSON.parse(getOrganizationData()));
  const [form] = Form.useForm();
  const [token, setToken] = useState<any>(null);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined);
  const [err, setErr] = useState<string>();
  const [load, setLoad] = useState<boolean>();
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let token = queryParams.get('token');
      // console.log('token ', token);
      setToken(token);
    }
  }, [location]);
  const onChange = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };
  const handleSubmit = () => {
    setLoad(true);
    form
      .validateFields()
      .then(done => {
        changePassword({
          variables: {
            token: token,
            password: password,
          },
        })
          .then(async result => {
            console.log('done>>>>>>', result);
            setLoad(false);
            if (result.data.changePassword) {
              notification.success({
                message: 'Success',
                description: 'Password changed successfully. Please wait redirecting to login page.',
              });
              if (token) {
                setTimeout(function () {
                  removeToken();
                  removeUserData();
                  history.push('/login');
                }, 2000);
              }
            } else {
            }
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
            }
            setLoad(false);
          });
      })
      .catch(err => {
        console.log('err', err);
        setLoad(false);
      });
  };
  return (
    <LoginWrapper>
      <div style={{ width: '80%', maxWidth: '300px', marginBottom: '4em' }}>
        <img
          src={organization.logo || `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = `https://via.placeholder.com/1000/FFFFFF/000000/?text=${organization.name}`;
          }}
          style={{ objectFit: 'contain', width: '100%', maxHeight: '200px' }}
          alt="organization logo"
        />
      </div>
      <FormWrapper>
        <Card
          title={<FormTitle>Change Password</FormTitle>}
          style={{
            maxWidth: '400px',
            minWidth: '320px',
            width: '90%',
            borderRadius: '5px',
            backgroundColor: '#fff',
            margin: '0 auto',
            boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.3)',
          }}
        >
          <p style={{ color: '#f00', marginBottom: '10px' }}>
            <strong>{err}</strong>
          </p>
          <Form layout="vertical" form={form} name="login">
            <Form.Item
              hasFeedback
              label="Password"
              name="password"
              labelAlign="left"
              rules={[{ required: true, message: 'Enter your new password' }]}
            >
              <Input.Password
                placeholder="Enter new password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={e => onChange(e.target.value, setPassword)}
              />
            </Form.Item>
            <Form.Item
              dependencies={['password']}
              hasFeedback
              label="Confirm Password"
              name="confirmPassword"
              labelAlign="left"
              rules={[
                { required: true, message: 'Renter your password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Renter password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onPressEnter={handleSubmit}
                onChange={e => onChange(e.target.value, setConfirmPassword)}
              />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AntdButton type="primary" onClick={handleSubmit} loading={load} style={{ marginLeft: '1em' }}>
                Submit
              </AntdButton>
            </div>
          </Form>
        </Card>
      </FormWrapper>
    </LoginWrapper>
  );
};

export default ChangePassword;
