import React, { useRef, useEffect } from 'react';
import { LayoutProps } from './interface';
import { Layout as AntdLayout } from 'antd';
import Header from './Header';
import { StyledLayout, StyledContent } from './LayoutStyles';
import Scrollbar from 'react-smooth-scrollbar';

interface Props extends LayoutProps {
  setShowFilterModal: Function;
}

const Layout: React.FC<Props> = ({ setShowFilterModal, children }) => {
  return (
    <StyledLayout>
      <Header toggleFilter={setShowFilterModal} />
      {/* <Scrollbar className="content-scrollbar"> */}
      <StyledContent id="styledContent">{children}</StyledContent>
      {/* </Scrollbar> */}
    </StyledLayout>
  );
};

export default Layout;
