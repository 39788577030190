import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragment';

export const CURRENTLY_PREMIERING_VIDEO_QUERY = gql`
  query currentlyPremeiringVideo {
    currentlyPremeiringVideo {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
