import { gql } from 'apollo-boost';

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on Organization {
    _id
    name
    provider
    subdomain
    city
    country
    description
    phoneNumber
    email
    theme
    status
    createdAt
    logo
    customData
  }
`;
