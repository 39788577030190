import { gql } from 'apollo-boost';

export const CUSTOM_BUBBLES_QUERY = gql`
  query customBubbles {
    customBubbles {
      _id
      bubbleType
      tag
      bubbleContent
    }
  }
`;
