import { gql } from 'apollo-boost';

export const TRAINER_FRAGMENT = gql`
  fragment Trainer on Trainer {
    _id
    name
    trainerDetailUrl
    trainerPicUrl
    createdAt
    updatedAt
  }
`;
