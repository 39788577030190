import { gql } from 'apollo-boost';
import { ORGANIZATION_FRAGMENT } from './../organizationFragment';

export const ORGANIZATION_FROM_SUBDOMAIN_QUERY = gql`
  query organizationFromSubdomain($domain: String!) {
    organizationFromSubdomain(domain: $domain) {
      ...Organization
      allowGuestLogin
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
