import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragment';

export const VIDEOS_ALL_ACTIVITY_QUERY = gql`
  query videosByAllActivity {
    videosByAllActivity {
      _id
      name
      videos {
        ...Video
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
