import styled from 'styled-components';

export const HomeWrapper = styled.div`
  width: 95%;
  max-width: 1820px;
  margin: 0 auto;
`;

export const TitleWrapper = styled.h1`
  margin-bottom: 0;
  padding-top: 1em;
  padding-bottom: 0.125em;
  font-size: 2rem;
`;

export const LoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 233px;
  @media (min-width: 380px) {
    height: 242px;
  }
  @media (min-width: 500px) {
    height: 333px;
  }
`;
