import React, { useState } from 'react';
import { Collapse, List, Modal, Button } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { USER_FOLLOWING_QUERY } from 'apollo/user/queries';
import { FOLLOW_TRAINER_MUTATION } from 'apollo/trainer/mutations';
import { FOLLOW_ACTIVITY_MUTATION } from 'apollo/activity/mutations';

const { Panel } = Collapse;

const FollowItem = ({ item, following, onFollow, onUnfollow }) => {
  const [loading, setLoading] = useState(false);

  const follow = async obj => {
    setLoading(true);
    await onFollow(obj);
    setLoading(false);
  };
  const unfollow = async obj => {
    setLoading(true);
    await onUnfollow(obj);
    setLoading(false);
  };

  return (
    <List.Item>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: '1rem' }}>{item.name}</div>
        {following.includes(item._id) ? (
          <Button loading={loading} onClick={() => unfollow(item)}>
            Unfollow
          </Button>
        ) : (
          <Button loading={loading} type="primary" onClick={() => follow(item)}>
            Follow
          </Button>
        )}
      </div>
    </List.Item>
  );
};

const FollowingModal = ({ visible, onDone }) => {
  const [trainers, setTrainers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [followedTrainers, setFollowedTrainers] = useState<any>([]);
  const [followedActivities, setFollowedActivities] = useState<any>([]);
  const [followTrainerMutation] = useMutation(FOLLOW_TRAINER_MUTATION);
  const [followActivityMutation] = useMutation(FOLLOW_ACTIVITY_MUTATION);

  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      setTrainers(data.trainers);
    },
    onError: err => {
      console.error('trainer err', err);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
    },
    onError: err => {
      console.error('activity err', err);
    },
  });
  useQuery(USER_FOLLOWING_QUERY, {
    onCompleted: data => {
      setFollowedActivities(data.userFollowing.followingActivity);
      setFollowedTrainers(data.userFollowing.followingTrainer);
    },
    onError: err => {
      console.error('activity err', err);
    },
  });

  const onFollowTrainer = async item => {
    try {
      const res = await followTrainerMutation({
        variables: {
          _id: item._id,
          type: true,
        },
      });
      if (res.data.followTrainer) {
        let temp = [...followedTrainers];
        temp.push(item._id);
        setFollowedTrainers(temp);
      }
      return res.data.followTrainer;
    } catch (e) {
      console.log('followTrainer error', e);
    }
  };

  const onUnfollowTrainer = async item => {
    try {
      const res = await followTrainerMutation({
        variables: {
          _id: item._id,
          type: false,
        },
      });
      if (res.data.followTrainer) {
        let temp = [...followedTrainers];
        let index = temp.indexOf(item._id);
        temp.splice(index, 1);
        setFollowedTrainers(temp);
      }
      return res.data.followTrainer;
    } catch (e) {
      console.log('followTrainer error', e);
    }
  };

  const onFollowActivity = async item => {
    try {
      const res = await followActivityMutation({
        variables: {
          _id: item._id,
          type: true,
        },
      });
      if (res.data.followActivity) {
        let temp = [...followedActivities];
        temp.push(item._id);
        setFollowedActivities(temp);
      }
      return res.data.followActivity;
    } catch (e) {
      console.log('followActivity error', e);
    }
  };

  const onUnfollowActivity = async item => {
    try {
      const res = await followActivityMutation({
        variables: {
          _id: item._id,
          type: false,
        },
      });
      if (res.data.followActivity) {
        let temp = [...followedActivities];
        let index = temp.indexOf(item._id);
        temp.splice(index, 1);
        setFollowedActivities(temp);
      }
      return res.data.followActivity;
    } catch (e) {
      console.log('followActivity error', e);
    }
  };

  return (
    <Modal visible={visible} onCancel={onDone} footer={null} title={<span style={{ fontSize: '1.25rem' }}>Followings</span>}>
      <span style={{ fontSize: '0.85rem' }}>
        If you like what you see from a trainer or an activity, you can follow or unfollow them. If you follow activity or trainer, you will be
        notified when they upload new video.
      </span>
      <Collapse ghost>
        <Panel header={<span style={{ fontSize: '1.15rem' }}>Trainers</span>} key="1">
          <List
            dataSource={trainers}
            renderItem={(item: any) => (
              <FollowItem item={item} following={followedTrainers} onFollow={onFollowTrainer} onUnfollow={onUnfollowTrainer} />
            )}
          />
        </Panel>
        <Panel header={<span style={{ fontSize: '1.15rem' }}>Activities</span>} key="2">
          <List
            dataSource={activities}
            renderItem={(item: any) => (
              <FollowItem item={item} following={followedActivities} onFollow={onFollowActivity} onUnfollow={onUnfollowActivity} />
            )}
          />
        </Panel>
      </Collapse>
    </Modal>
  );
};

export default FollowingModal;
