import styled from 'styled-components';
import { Button } from 'antd';
export const CardWrapper = styled.div`
  width: 200px;
  margin-right: 1.5em;
  @media (min-width: 380px) {
    width: 280px;
  }
  @media (min-width: 500px) {
    width: 400px;
  }
`;
export const LeftArrowDiv = styled.div`
  position: absolute;
  height: 232px;
  z-index: 1;
  @media (min-width: 380px) {
    height: 242px;
  }
  @media (min-width: 500px) {
    height: 334px;
  }
`;
export const RightArrowDiv = styled.div`
  position: absolute;
  height: 232px;
  z-index: 1;
  margin-right: 2em;
  right: 0;
  @media (min-width: 380px) {
    height: 242px;
  }
  @media (min-width: 500px) {
    height: 334px;
  }
`;
export const LeftButton = styled.div`
  position: relative;
  cursor: pointer;
  top: 115px;
  @media (min-width: 380px) {
    top: 120px;
  }
  @media (min-width: 500px) {
    top: 165px;
  }
`;
export const RightButton = styled.div`
  position: relative;
  cursor: pointer;
  top: 115px;
  @media (min-width: 380px) {
    top: 120px;
  }
  @media (min-width: 500px) {
    top: 165px;
  }
`;
