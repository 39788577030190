import { gql } from 'apollo-boost';

export const COMMENT_FRAGMENT = gql`
  fragment Comment on Comment {
    _id
    content
    totalLikes
    totaldisLikes
    isLiked
    isdisLiked
    name
    picUrl
    children {
      _id
      content
      totalLikes
      totaldisLikes
      isLiked
      isdisLiked
      name
      picUrl
      children {
        _id
        content
        totalLikes
        totaldisLikes
        isLiked
        isdisLiked
        name
        picUrl
      }
    }
  }
`;
