import { gql } from 'apollo-boost';

export const CUSTOM_BUTTONS_QUERY = gql`
  query customButtons {
    customButtons {
      _id
      title
      url
      action
      icon
    }
  }
`;
