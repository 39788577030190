import { gql } from 'apollo-boost';

export const ANNOUNCEMENT_QUERY = gql`
  query announcement {
    announcement {
      title
      description
      startTime
      selfServiceSessionUrl
      resourceName
      instructor
      type
    }
  }
`;
