import { gql } from 'apollo-boost';

export const EXTERNAL_CLIENT_LOGIN = gql`
  mutation externalClientLogin($username: String!, $password: String!, $organizationId: String!) {
    externalClientLogin(username: $username, password: $password, organizationId: $organizationId) {
      jwt
      user {
        username
        firstName
        lastName
        userId
        avatarUrl
        organizationId
        role
        email
      }
    }
  }
`;
