import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragment';

export const RELATED_VIDEOS_QUERY = gql`
  query relatedVideos($_id: ID!, $activity: String!) {
    relatedVideos(_id: $_id, activity: $activity) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
