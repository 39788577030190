import React, { useState } from 'react';
import { List } from 'antd';
import CommentComp from './../CommentComp/CommentComp';
import CommentBox from './../CommentBox/CommentBox';
import { useQuery } from '@apollo/react-hooks';
import { COMMENTS_IN_VIDEO_QUERY } from 'apollo/comment/queries';
import Loader from 'react-loader-spinner';
import CommentList from 'component/CommentList/CommentList';

const VideoComments = ({ videoId, videoDetails }) => {
  const [comments, setComments] = useState<null | Array<Object>>(null);

  useQuery(COMMENTS_IN_VIDEO_QUERY, {
    variables: { _id: videoId },
    onCompleted: data => {
      // console.log('data comments', data);
      setComments(data.commentsInVideo);
    },
    onError: err => {
      console.log(err);
    },
  });

  const onCommentAdd = newComment => {
    setComments((prev: any) => [...prev, newComment]);
  };

  return (
    <div style={{ background: 'white', marginTop: 15, padding: '1em 1em' }}>
      {comments ? (
        <>
          <CommentBox videoDetails={videoDetails} videoId={videoId} parentId={null} onCommentAdd={onCommentAdd} />
          {comments.length > 0 && <CommentList setComments={setComments} videoDetails={videoDetails} comments={comments} canReply={true} />}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </div>
  );
};
export default VideoComments;
