import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragment';

export const VIDEOS_BY_TYPE_QUERY = gql`
  query videosByType($type: String!, $value: String) {
    videosByType(type: $type, value: $value) {
      _id
      name
      videos {
        ...Video
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
